var apiUrl;

if (process.env.REACT_APP_ENV === "production")
  apiUrl = "https://sandbox.api.hr-portals.com";
else if (process.env.REACT_APP_ENV === "sandbox")
  apiUrl = "https://sandbox.api.hr-portals.com";
else {
  apiUrl = "https://sandbox.api.hr-portals.com";
}

const GOOGLE_APT_KEY = "xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx";


export { apiUrl, GOOGLE_APT_KEY };
