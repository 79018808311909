import { Route } from "react-router-dom";


import  News from "./News";

import NewsContainer from "./NewsContainer";

export default (
  <>
    <Route
      path="/news"
      exact
      element={
  
          <News/>
      
      }
    >
      <Route
        index
        element={
      
            <NewsContainer />
     
        }
      />
    </Route>
  </>
);
