import { Route } from "react-router-dom";


import  About from "./About";

import AboutContainer from "./AboutContainer";

export default (
  <>
    <Route
      path="/about"
      exact
      element={
  
          <About/>
      
      }
    >
      <Route
        index
        element={
      
            <AboutContainer />
     
        }
      />
    </Route>
  </>
);
