import React, { useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import { motion, Variants } from "framer-motion";
import styled from "styled-components";
import MainTitle from "../../MainTitle";
import "./WeDo.scss";
const StyledWeDo = styled.div`
.main{
  max-width: 1200px ;
  position: relative;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  overflow-x: hidden;
 
  
}


.cText{
  
  color:white;
  font-weight:400;
  font-family:"Inter";
  font-size:25px;
  line-height:50px;
  @media screen and (max-width:1200px){
      
    font-size:23px;
    line-height:46px;

  }
  @media screen and (max-width:991px){
    font-size:21px;
    line-height:42px;
  }
  @media screen and (max-width:767px){
    font-size:19px;
    line-height:38px;
  }
  @media screen and (max-width:464px){
    font-size:17px;
    line-height:34px;
  }
}
}
.div1{
  width:275px;
  height:100px;
  margin:0px 20px;


  
  @media screen and (max-width:1200px){
      
    width:230px;
  height:95px;



  }
  @media screen and (max-width:991px){
    width:200px;
    height:90px;

   

  }
  @media screen and (max-width:767px){
    width:250px;
    height:85px;
   
    margin-left:50px;
  
  }
  @media screen and (max-width:464px){
    width:230px;
    height:80px;
    margin:auto;
   

  }
}


`;
const cardVariants = {
  offscreen: {
    y: -200,
    x:-200,
    opacity: 0
  },
  onscreen: {
    y: 0,
    x:0,
    opacity: 1,
    transition: {
      ease: "easeIn",
      bounce: 0.4,
      duration: 1
      ,delay:0.2
    }
  }
};
const cardVariants1={
  offscreen: {
    y: -200,
    x:-200,
    opacity: 0
  },
  onscreen: {
    y: 0,
    x:0,
    opacity: 1,
    transition: {
      ease: "easeIn",
      bounce: 0.4,
      duration: 1
      ,delay:0.8
    }
  }
};
const cardVariants2={
  offscreen: {
    y: -200,
    x:-200,
    opacity: 0
  },
  onscreen: {
    y: 0,
    x:0,
    opacity:1,
    transition: {
      ease: "easeIn",
      bounce: 0.4,
      duration: 1
      ,delay:1.4
    }
  }
};
const cardVariants3 ={
  offscreen: {
    y: -200,
    x:-200,
    opacity: 0
  },
  onscreen: {
    y: 0,
    x:0,
    opacity: 1,
    transition: {
      ease: "easeIn",
      bounce: 0.4,
      duration: 1,
      delay: 2,
    },
  },
};

function WeDo() {
  useEffect(() => {}, []);

  const [click, setClick] = useState(false);
  const [id, setID] = useState(null);
  const Clicked = (e) => {
    setID(e.target.id);
    setClick(!click);
    setTimeout(() => {
      setClick(null);
    }, 1500);
  };
  return (
    <StyledWeDo>
        
  <MainTitle fTitle="What" sTitle="We Do"
  subtitle="ACCELERATE THROUGH SOFTWARE" 
  details="WE are a family of more than 30 specialists in design, branding, programming and software development. Ready to take your technical experience to last-level applications in web and mobile development. And support your business from consultancy to delivery and beyond."
  />
  <Row  xs={1} md={4} sm={2} className="g-4 cardMargin">
<Col >

 <motion.div
      className="card bg-dark div1"
      variants={cardVariants}
      initial="offscreen"
      whileInView="onscreen"
      viewport={{ once: true }}
  
    > 
  
    <Card.Body className="text-center" id="1">
      <Card.Text className='cText' >+78 Employees</Card.Text>
    </Card.Body>
</motion.div>

</Col>
<Col >

 <motion.div
      className="card bg-dark div1"
      variants={cardVariants1}
      initial="offscreen"
      whileInView="onscreen"
      viewport={{ once: true }}
  
    > 

    <Card.Body className="text-center" id="2">
      <Card.Text className='cText' >+100 Interns</Card.Text>
    </Card.Body>
</motion.div>

</Col>
<Col >
<motion.div
      className="card bg-dark div1"
      variants={cardVariants2}
      initial="offscreen"
      whileInView="onscreen"
      viewport={{ once: true}}
      
    >
   
    <Card.Body className="text-center" id="3">
      <Card.Text className='cText' >+3 partners</Card.Text>
    </Card.Body>
</motion.div>
</Col>
<Col >
<motion.div
      className="card bg-dark div1"
      variants={cardVariants3}
      initial="offscreen"
      whileInView="onscreen"
      viewport={{ once: true}}
    >
     {/* <div className="card bg-dark div1 motion4" > */}
    <Card.Body className="text-center" id="4">
      <Card.Text className='cText' >+150 client</Card.Text>
    </Card.Body>
</motion.div>
</Col>

  </Row>

  </StyledWeDo>
    )
}

export default WeDo;
