import React, { useRef, useState } from "react";
import "./styles/style.scss";
import { Col, Container, Row } from "react-bootstrap";
import CustomProjects from "./CustomProjects";
import NavHeader from "../home/NavHeader";
import NavContent from "../NavContent";
import SectionTitleContent from "../SectionTitleContent";
import ClientReviewSlider from "./ClientReviewSlider";
import Pagination from "../../ui/pagination/Pagination";
function ProjectsContainer() {
  const [selected, setSelected] = useState("All");
  const [selectedGroup, setSelectedGroup] = useState(0);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(1);
  const [projectCount, setProjectCount] = useState(1);
  const [currentPage, setCorrentPage] = useState([]);
  const titleDiv = document.querySelector("#t1");
  const links = [
    { name: "All", id: 0 },
    { name: "Activation", id: 1 },
    { name: "B2C", id: 2 },
    { name: "Consoluting", id: 3 },
    { name: "Energy", id: 4 },
    { name: "Engineering", id: 5 },
    { name: "Finance", id: 6 },
    { name: "Government", id: 7 },
    { name: "Healthcare", id: 8 },
    { name: "Insurance", id: 9 },
    { name: "Legal", id: 10 },
    { name: "Manufacturing", id: 11 },
    { name: "Media & Marketing", id: 12 },
    { name: "Non-profit", id: 13 },
    { name: "Real Estate", id: 14 },
    { name: "SaaS", id: 15 },
    { name: "Science", id: 16 },
    { name: "Technology", id: 17 },
  ];
  return (
    <>
      <NavHeader />
      <NavContent
        fTitle="OUR"
        sTitle=" PROJECTS"
        subtitle="ACCELERATE THROUGH SOFTWARE"
        details="Read Hnndes's case studies and check out our achievements. Here's how we started our strategic partnerships with our customers and helped their companies grow and scale on national and international levels."
      />

      <Container>
        <Row>
          {/* <Col sm="4" md="4" lg="3">
            <div className="nav" id="t1">
              <div className="wrap">
                <ul>
                  {links.map((link) => (
                    <>
                      <li
                        className={
                          selected == link.name ? "selected" : undefined
                        }
                        onClick={() => {
                          titleDiv.scrollIntoView();
                          setSelectedGroup(link.id);
                          setSelected(link.name);
                        }}
                      >
                        {link.name}
                      </li>
                    </>
                  ))}
                </ul>
              </div>
            </div>
          </Col> */}
          <Col lg="12" md="8" sm="8" className="body">
            <CustomProjects
              // selectedGroup={selectedGroup}
              // setSelectedGroup={setSelectedGroup}
              // count={count}
              // setCount={setCount}
              // currentPage={currentPage}
              // setCorrentPage={setCorrentPage}
              // page={page + 1}
              // setPage={setPage}
              // setProjectCount={setProjectCount}
            />
            {/* <Pagination
              sx={{ mt: 20 }}
              data-testid="product-pagination"
              count={projectCount}
              page={page}
              onPageChange={(e) => {
                setPage(e + 1);
              }}
              rowsPerPage={4}
              setPage={setPage}
            /> */}
          </Col>
        </Row>
        {/* <Row>
          <SectionTitleContent
            fTitle="CONTACT"
            sTitle=" US"
            subtitle="Get in touch with our experts"
            details="Please select your type of project request and submit the form, or contact us at 
            for more information on hnndes's services and technologies.Read Hnndes's case studies and check out our achievements. Here's how we started our strategic partnerships with our customers and helped their companies grow and scale on national and international levels."
          
          />
          <div className="overflow-hidden mobileheight">
            <ClientReviewSlider />
          </div>
        </Row> */}
      </Container>
    </>
  );
}

export default ProjectsContainer;
