import React from 'react'
import { Card } from 'react-bootstrap'
import styled from "styled-components";
import "./About.scss";

const StyledApproach = styled.div`


    



`;
function TeamCard({key,image,name,work}) {
  return (
    <div className="TteamDiv"  key={key} >
    
<div className='teamDiv'>

<svg className='bcgteam'  width="359" height="359" viewBox="0 0 359 359" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M339.362 242.623C336.035 250.415 332.174 257.964 327.807 265.22C308.334 297.399 278.898 322.935 245.156 339.152C210.939 355.6 171.747 362.827 134.067 357.009C121.15 355.013 85.734 349.072 54.3811 321.016C-5.97718 267.013 -18.8866 160.086 29.8042 84.7266C65.2415 29.8879 128.758 -1.20025 189.126 0.353365C227.058 1.33156 265.805 -5.55352 300.409 15.0422C322.708 28.3085 338.501 50.8805 347.325 75.3374C366.245 127.765 361.156 191.886 339.362 242.623Z" fill="#B9BCC5"/>
<defs>
<linearGradient id="paint0_linear_2005_12526" x1="0" y1="179.5" x2="359" y2="179.5" gradientUnits="userSpaceOnUse">
<stop stop-color="#7FF593"/>
<stop offset="0.515625" stop-color="#68EAAE"/>
<stop offset="1" stop-color="#53DFC7"/>
</linearGradient>
</defs>
</svg>

<img className='imgteam' src={image} />

     
   
   
    </div>
    <div className='ctdiv'>
      <p className='cardTitle' >{name}</p>
      <p className='cardText' >
  {work}
      </p>
    </div>
  </div>
  )
}

export default TeamCard
