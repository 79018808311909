import { ThemeProvider } from "styled-components";

import UtilsProvider from "./utilsProvider";
import theme from "../theme";
import HnndesProvider from "view/provider";
//import HnndesProvider from "../view/provider"


export default function providers({ children }) {
  return (
   
    <ThemeProvider theme={theme}>
      <UtilsProvider>
        <>
     <HnndesProvider>
      {children}
      </HnndesProvider>
      </>
      </UtilsProvider>
    </ThemeProvider>
    
  );
}

