import React, { useEffect, useState } from 'react'
import NavHeader from '../home/NavHeader'
import NavContent from '../NavContent'
import styled from "styled-components";
import './contact.scss';
import { Col, Form, Row } from 'react-bootstrap';
import papper from "../../assets/peapper.svg"
import locationBtn from "../../assets/locationBtn.svg"
import { motion } from 'framer-motion';
import MainTitle from '../MainTitle';
import pepper from "../../assets/peapper.svg"
const StyledContact = styled.div`
 
  .mainc{
   
     position: relative;
 
      margin-bottom:10px;
  
  
  
      max-width: 1200px;
  
    margin: 0 auto;
    
    justify-content: center;
    align-items: center;
    padding: 2rem;
      @media screen and (max-width:991px){
        margin-top: -40px;
      
      
      }
      @media screen and (max-width:747px){
        margin-top: -50px;
        padding: 1rem;
      
      
      }
  @media screen and (max-width:474px){
    margin-top: -55px;
  
  
  }
      
    }

  `;
  const t1 = {
    offscreen: {
      y: 200,
      opacity: 0,
    },
    onscreen: {
      y: 0,
      opacity: 1,
      transition: {
        ease: "easeIn",
        bounce: 0.4,
       duration:1.5,
        delay: 0.1,
      },
    },
  };
  const t2 = {
    offscreen: {
      y: 200,
      opacity: 0,
    },
    onscreen: {
      y: 0,
      opacity: 1,
      transition: {
        ease: "easeIn",
        bounce: 0.4,
       duration:1.5,
        delay: 0.3,
      },
    },
  };
  const t3 = {
    offscreen: {
      y: 100,
      opacity: 0,
    },
    onscreen: {
      y: 0,
      opacity: 1,
      transition: {
        ease: "easeIn",
        bounce: 0.4,
       duration:1.5,
        delay: 0.7,
      },
    },
  };
  const t4 = {
    offscreen: {
      y: 50,
      opacity: 0,
    },
    onscreen: {
      y: 0,
      opacity: 1,
      transition: {
        ease: "easeIn",
        bounce: 0.4,
       duration:1.5,
        delay: 0.8,
      },
    },
  };

  
function ContactContainer() {
  const [x ,setX] = useState(0)
  const [row , setRow] = useState(10)
  useEffect(() => {
    const handleResize = () => {
      setX(window.innerWidth)
     
    };
    window.addEventListener('resize', handleResize);
   
  }, []);


  useEffect(()=>{
    console.log(x,"x")
    if(x > 1350 ){
      setRow(10)
    }
    if(x > 1200 && x < 1350){
      setRow(6)
    }
    if(x > 991 && x < 1200)
    { setRow(6)}
    if(x >950 && x < 991)
    { setRow(4)}

    if(x >767 && x < 950)
    { setRow(3)}
    if(x >474 && x < 767)
    { setRow(1)}
    if(x <474 )
    { setRow(5)}
  },[window.innerWidth])
const[id,setID]=useState("0")
const handleHover=(e)=>{
  setID(e.target.id);
  console.log("id",id);
}
  return (
    <div>
 <NavHeader/>
        <NavContent fTitle="Contact"
         sTitle=" Us" 
         subtitle="LOOKING FOR A SOFTWARE COMPANY?" 
         details="In order to talk to us about any project you desire, we are always available to answer you"
         />

       
         

      <div className="mainc">
      <div className='zz'>
          
          <div className='rightForm'>
        
            <div >
            <p className='pRrank'>Write us a few words about your project and we’ll prepare a proposal for you within 24 hours.</p>
            <Form.Control  className="fcInput" type="text" placeholder="Name" />
      
        <Form.Control className="fcInput" type="text" placeholder="Email" />
        
        <Form.Control className="fcInput" type="text" placeholder="Phone number" />
        
        <Form.Control className="fcInput" type="text" placeholder="Company name" />
        
        <textarea  class="form-control fcInputArea" placeholder="Your message"   ></textarea>
   
        <button type="button" class="rankBtn">Send</button>
        <br/>
     
        <p className='pbRank'>If you need to  have a DM first, just contact us at info@hnndes.com</p>
            </div>
            </div>
  </div>

  <div className='leftForm'>
        
            <div >
            <p className='pRrank'>Write us a few words about your project and we’ll prepare a proposal for you within 24 hours.</p>
            <Form.Control  className="fcInput" type="text" placeholder="Name" />
      
        <Form.Control className="fcInput" type="text" placeholder="Email" />
        
        <Form.Control className="fcInput" type="text" placeholder="Phone number" />
        
        <Form.Control className="fcInput" type="text" placeholder="Company name" />
        
        <textarea  class="form-control fcInputArea" placeholder="Your message"   ></textarea>
   
        <button type="button" class="rankBtn">Send</button>
        <br/>
     
        <p className='pbRank'>If you need to  have a DM first, just contact us at support@hnndes.com</p>
            </div>
            </div>

     <Row className="firstRow"  md={1} xs={1}><Col> 
     <div className='nextStep' >
      <p className='ptdiv'>What will be next step?</p>
      <p className='psdiv'>You are one step closer to build your perfect product. </p>
     </div>
     
     </Col></Row>
     <Row className='steps' md={1} xs={1}><Col>
     
     <motion.div
     
     variants={t1}
     initial="offscreen"
     whileInView="onscreen"
     viewport={{ once: true }} className='divStep'>
<span className='ptdiv1'>01</span><span className='pStep'> Share your idea</span>

     </motion.div>
     <motion.div
     
     variants={t2}
     initial="offscreen"
     whileInView="onscreen"
     viewport={{ once: true }} className='divStep'>
<span className='ptdiv1'>02</span><span className='pStep'> Discuss it with our expert</span>

     </motion.div>
     <motion.div
     
     variants={t3}
     initial="offscreen"
     whileInView="onscreen"
     viewport={{ once: true }} className='divStep'>
<span className='ptdiv1'>03</span><span className='pStep'>Get an estimation of a project</span>

     </motion.div>
     <motion.div
     
     variants={t4}
     initial="offscreen"
     whileInView="onscreen"
     viewport={{ once: true }} className='divStep1'>
<span className='ptdiv1'>04</span><span className='pStep'>Start the project</span>

     </motion.div>
     
     </Col></Row>

     <Row lg={3} md={2} xs={1} sm={1} className='secondRow' >
<Col  className='lebanCol'>
<div  className='parentContact'>


<div  className='secondContact' >
<p className='lbanP'  >Lebanon</p>
<p  className='lbanAddP'  >Lebanon, Sin El fil, Gazal street, Chehade aboud Bldg, 2nd  floor</p>
{/* <p  className='lbanPhoneP'  >+961 1499559 </p> */}
<p  className='lbanPhoneP' >+961 71064571 </p>
<a href='https://www.google.com/maps?q=33.8826026916504,35.5357856750488' target="_blank">
<img src={locationBtn} className='locationBtn'/>
</a>
</div>
<div  className='secondContact1' >
<a href='https://www.google.com/maps?q=33.8826026916504,35.5357856750488' target="_blank">
<p className='lbanP'  >Lebanon</p>
<p  className='lbanAddP'  >Lebanon, Sin El fil, Gazal street, Chehade aboud Bldg, 2nd  floor</p>
{/* <p  className='lbanPhoneP'  >+961 1499559 </p> */}
<p  className='lbanPhoneP' >+961 71064571 </p>


</a>
</div>

</div>
</Col>
<Col  className='talent1' >
<div  className='parentContact' >
  
<div  className='secondContact'>
<p  className='lbanS'>Syria</p>
<p className='lbanAddP' >Homs, Al-Dablan Street, Al-Ayoubi Building, fourth floor</p>
<p  className='lbanPhoneP' >+963 940 007 018</p>
<a  target="_blank" href='https://www.google.com/maps?q=34.730876,36.706914'><img src={locationBtn} className='locationBtn'/></a>
</div>
<div  className='secondContact1'>
<a  target="_blank" href='https://www.google.com/maps?q=34.730876,36.706914'>

<p  className='lbanS'>Syria</p>
<p className='lbanAddP' >Homs, Al-Dablan Street, Al-Ayoubi Building, fourth floor</p>
<p  className='lbanPhoneP' >+963 940 007 018</p>
</a>
</div>

</div>
</Col>
<Col  className='talent'   >

<MainTitle fTitle="Get In" sTitle=" Touch" subtitle="TELL US ABOUT YOUR PROJECT!" details=""/>
<div className='emailDiv'><span style={{paddingLeft:"5px"}}>Email </span><span className='info'>   info@hnndes.com</span></div>
<div className='phoneDiv'><span style={{paddingLeft:"5px"}}>Phone </span><span className='info'>  +961 4 652 415</span></div>

</Col>



</Row>

        </div>



      
      
       

   

        </div>
       
  )
}

export default ContactContainer
