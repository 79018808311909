import { Route } from "react-router-dom";

import { RequireAuth } from "../../services/auth";
import { Home } from "./Home";

import HomeContainer from "./HomeContainer";

export default (
  <>
    <Route
      path="/"
      exact
      element={
        // <RequireAuth>
          <Home />
        // </RequireAuth>
      }
    >
      <Route
        index
        element={
          // <RequireAuth>
            <HomeContainer />
          // </RequireAuth>
        }
      />
    </Route>
  </>
);
