import React from 'react'
import Layout from '../../layout/Layout';
import { Outlet } from 'react-router-dom';

function News() {
    return (
        <Layout>
          <Outlet />
        </Layout>
      );
}

export default News
