// *** React
import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Navbar, Container, Button, Modal } from "react-bootstrap";
import toggle from "./ph_list.png";
import logo from "./LogoH2.png";
import bgi from "./mBg.png";

import styled from "styled-components";

const StyledHeader = styled.nav`
  .title {
    font-size: 25px;
    font-weight: 900;
    cursor: pointer;
    color: black;
  }
  .subTitle {
    padding-bottom: 30px;
    font-size: 20px;
    font-weight: 700;

    color: black;
  }
`;

function Header() {
  const [show, setShow] = useState(false);
  const [id, setId] = useState(0);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [click, setClick] = useState(false);
  const handleClick = (e) => {
    setClick(!click);
    setId(e.target.id);
  };

  return (
    <StyledHeader>
      <Navbar className="bg-body-tertiary">
        <Container>
          <Navbar.Brand href="\">
            <img alt="" src={logo} className="d-inline-block align-top" />
          </Navbar.Brand>
          <Navbar.Toggle />
          <Navbar.Collapse className="justify-content-end">
            <Navbar.Text>
              <Button
                style={{ paddingRight: "0px" }}
                variant="light"
                onClick={handleShow}
              >
                <img src={toggle} />
              </Button>
              <span
                style={{
                  lineHeight: "60px",
                  fontSize: "20px",
                  fontWeight: "bold",
                  color: "black",
                }}
              >
                {" "}
                MENU
              </span>
            </Navbar.Text>
          </Navbar.Collapse>
          <Modal show={show} onHide={handleClose} fullscreen={true}>
            <Modal.Header closeButton>
              <Modal.Title>
                <img alt="" src={logo} className="d-inline-block align-top" />
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div
                id="1"
                className="d-flex justify-content-center title"
                onMouseOver={handleClick}
                onMouseLeave={() => setClick(false)}
              >
                <a style={{ color: "black" }} href="/">
                  {" "}
                  Home
                </a>
              </div>
              <div className="d-flex justify-content-center subTitle">
                {click && id === "1"
                  ? "Creating Superior Digital Experiences"
                  : ""}
              </div>
              <div
                id="2"
                className="d-flex justify-content-center title"
                onMouseOver={handleClick}
                onMouseLeave={() => setClick(false)}
              >
                Services
              </div>
              <div className="d-flex justify-content-center subTitle">
                {click && id === "2"
                  ? "Creating Superior Digital Experiences"
                  : ""}
              </div>
              <div
                id="3"
                className="d-flex justify-content-center title"
                onMouseOver={handleClick}
                onMouseLeave={() => setClick(false)}
              >
                portfolio
              </div>
              <div className="d-flex justify-content-center subTitle">
                {click && id === "3"
                  ? "Creating Superior Digital Experiences"
                  : ""}
              </div>
              <div
                id="4"
                className="d-flex justify-content-center title"
                onMouseOver={handleClick}
                onMouseLeave={() => setClick(false)}
              >
                <a style={{ color: "black" }} href="/about">
                  {" "}
                  About
                </a>
              </div>
              <div className="d-flex justify-content-center subTitle">
                {click && id === "4"
                  ? "Creating Superior Digital Experiences"
                  : ""}
              </div>
              <div
                id="5"
                className="d-flex justify-content-center title"
                onMouseOver={handleClick}
                onMouseLeave={() => setClick(false)}
              >
                <a style={{ color: "black" }} href="/contact">
                  {" "}
                  Contact
                </a>
              </div>
              <div className="d-flex justify-content-center subTitle">
                {click && id === "5"
                  ? "Creating Superior Digital Experiences"
                  : ""}
              </div>
            </Modal.Body>
          </Modal>
        </Container>
      </Navbar>
    </StyledHeader>
  );
}

export default Header;
