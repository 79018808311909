import Card from 'react-bootstrap/Card';
import {motion} from 'framer-motion'
import { useState } from 'react';
import styled from "styled-components";

const StyledCard= styled.div`
.card{
  box-shadow: 0px 0px 5px 1px  rgba(83, 223, 199, 0.2);
  margin-bottom:30px;
  margin-left:30px;
  @media screen and (max-width:474px){
    margin-left:0px;


  }
}
.card:hover {

  background-image: linear-gradient(to right,#7FF593, #68EAAE, #53DFC7);
}
.subTitle{
 
  margin-bottom:10px;
  font-family:Inter;
  color:black;
  font-weight:700;
  font-size:22px;
  @media screen and (max-width:1200px){
      
    font-weight:700;
    font-size:22px


  }
  @media screen and (max-width:991px){
    font-weight:600;
    font-size:20px
    
  }
  @media screen and (max-width:767px){
    font-weight:600;
    font-size:18px
  }
  @media screen and (max-width:474px){
    font-weight:600;
    font-size:16.5px
  }
}
.content{
  font-size:18px;
  line-height:22px;
  text-align:left;
  @media screen and (max-width:1200px){
      
    font-size:19px;


  }
  @media screen and (max-width:991px){
    font-size:18px;
    
  }
  @media screen and (max-width:767px){
    font-size:17px;
  }
  @media screen and (max-width:474px){
    font-size:16px;
  }
}
.card-body1{
  height: 320px;
  @media screen and (max-width:1200px){
      
    height: 355px;
  
  
    }
    @media screen and (max-width:991px){
      height: 375px;
      
    }
    @media screen and (max-width:767px){
      height: 230px;
    }
    @media screen and (max-width:578px){
      height: 260px;
    }
    @media screen and (max-width:474px){
      height: 300px;
    }
}
`;



function ValuesCard({img,title,content}) {
  
  
  return (
    <StyledCard>
    <Card className='card'
   
     style={{fontFamily:'Inter'}}>
      <Card.Body  className='card-body1'>
        <Card.Subtitle  className='subTitle' >
          <img style={{width:"70px",height:"70px",marginRight:"5px"}}
        
        src={img}/>{title}</Card.Subtitle>
        <Card.Text className='content' >
         {content}
        </Card.Text>
      </Card.Body>
    </Card>
    </StyledCard>
  );
}

export default ValuesCard
;