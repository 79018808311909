import React, { useEffect, useState } from "react";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";

import Service from "./Service";

import styled from "styled-components";
import MainTitle from "../../MainTitle";
const StyledWeOffer = styled.div`
  .main {
    max-width: 1200px;
    position: relative;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    overflow-x: hidden;
    overflow-y: hidden;
  }

  .unHover {
    text-align: left;

    color: rgba(0, 0, 0, 0.7);

    width: 320px;
    font-family: "Poppins";
    font-size: 19px;
    font-weight: 700;

    cursor: pointer;

    @media screen and (max-width: 1200px) {
      font-size: 19px;
    }
    @media screen and (max-width: 991px) {
      font-size: 18px;
    }
    @media screen and (max-width: 767px) {
      font-size: 17px;
    }
    @media screen and (max-width: 474px) {
      font-size: 16px;
    }
  }
  .Hover {
    text-align: left;

    color: black;

    width: 320px;
    font-family: "Poppins";
    font-size: 19px;
    font-weight: 700;
  
    background: linear-gradient(
      rgba(127, 245, 147, 1),
      rgba(104, 234, 174, 1),
      rgba(83, 223, 199, 1)
    );
    padding-left: 15px;
    border-left: 2px solid;
    cursor: pointer;
     transition: 0.5s ease-in 0.5s;

    @media screen and (max-width: 1200px) {
      font-size: 19px;
    }
    @media screen and (max-width: 991px) {
      font-size: 18px;
    }
    @media screen and (max-width: 767px) {
      font-size: 17px;
    }
    @media screen and (max-width: 474px) {
      font-size: 16px;
    }
  }

  .mobile {
    display:none;
    @media screen and (min-width: 767px) {
      display:none !important;
    }

    @media screen and (max-width: 767px) {
      display:block !important;
      
    }
    @media screen and (max-width: 474px) {
      display:block !important;

    }
  }
  .web {
    display:block;

    @media screen and (min-width: 767px) {
      display:block !important;
    }

    @media screen and (max-width: 767px) {
      display:none  !important;
    }
    @media screen and (max-width: 474px) {
      display:none !important;
    }
  }
  .colbr {
    border-right: 4px solid black;
    height:450px;
   
   
    @media screen and (max-width: 767px) {
      border-right: 0px ;
      height:fit-content;
    }
    @media screen and (max-width: 474px) {
      border-right: 0px;
      height:fit-content;
      margin-top:0px;
      line-height:25px;
    }
  }
  .details {
    visibility: visible;
    opacity: 1;
    max-height: fit-content;
   transition: all .8s ease-out;
  }
  .undetails {
    visibility: hidden;
    opacity: 0;
    max-height: 0px;
   transition: all .8s ease-out;
  }
  .weOffer{
    margin-left:220px;
    width:1176px;
    @media screen and (max-width: 1200px) {
      margin-left:100px;
      width:1200px;
    }
    @media screen and (max-width: 991px) {
      margin-left:50px;
      width:800px;
    }
    @media screen and (max-width: 767px) {
      margin-left:40px;
    }
    @media screen and (max-width: 474px) {
      margin-left:40px;
     
    }
  }
`;

function WeOffer() {
  const [id, setID] = useState(1);
  const [key, setKey] = useState(1);
  const handleMouseOver = (e) => {
    switch (e.target.id) {
      case "1":
        setID(1);
        setKey(1);
        console.log("key", key);

        break;
      case "2":
        setID(2);
        setKey(2);
        console.log("key", key);
        break;
      case "3":
        setID(3);
        setKey(3);
        break;
      case "4":
        setID(4);
        setKey(4);
        break;
      case "5":
        setID(5);
        setKey(5);
        break;
    }
  };
  return (
    <StyledWeOffer>

        <MainTitle
          fTitle="What"
          sTitle="We Offer"
          subtitle="FULL-CYCLE SERVICES FOR YOUR NEEDS"
          details=" We provide end-to-end app and web development services at affordable rates. Also we help global brands to develop digital products from scratch."
        />
        <Row  xs={1} md={2} sm={2} className="g-4 weOffer">
          <Col md="4" className="colbr">
            <br></br>

            <Row>
              <div
                id="1"
                onClick={handleMouseOver}
                className={id === 1 ? "Hover" : "unHover"}
              >
               Web development
              </div>
              <div className={id === 1 ? "details mobile" : "undetails mobile"}>
                <Service id="1" />
              </div>
            </Row>
            <br />
            <Row>
              <div
                id="2"
                onClick={handleMouseOver}
                className={id === 2 ? "Hover" : "unHover"}
              >
                Mobile APPS development
              </div>
              <div className={id === 2 ? "details mobile" : "undetails mobile"}>
                <Service id="2" />
              </div>
            </Row>
            <br />
            <Row>
              <div
                id="3"
                onClick={handleMouseOver}
                className={id === 3 ? "Hover" : "unHover"}
              >
                {" "}
                Blockchain & Decentralized Applications
              </div>
              <div className={id === 3 ? "details mobile" : "undetails mobile"}>
                <Service id="3" />
              </div>
            </Row>
            <br />
            <Row>
              <div
                id="4"
                onClick={handleMouseOver}
                className={id === 4 ? "Hover" : "unHover"}
              >
               Digital transformation
              </div>
              <div className={id === 4 ? "details mobile" : "undetails mobile"}>
                <Service id="4" />
              </div>
            </Row>
            <br />
            <Row>
              <div
                id="5"
                onClick={handleMouseOver}
                className={id === 5 ? "Hover" : "unHover"}
              >
                Experience design
              </div>
              <div className={id === 5 ? "details mobile" : "undetails mobile"}>
                <Service id="5" />
              </div>
            </Row>
          </Col>

          <Col md="8">
            <div className="web">
              <Service id={key.toString()} />
            </div>
          </Col>
        </Row>
     
    </StyledWeOffer>
  );
}

export default WeOffer;
