import * as ReactDOMServer from "react-dom/server";
import styled, { css } from "styled-components";
import Input from "../Input";

const StyledOptions = styled.div`
  background-color: ${(props) => props.theme.color.white};
  border: ${(props) => props.theme.color.gray300} 1px solid;
  /* box-shadow: 0 0 10px 10px rgba(0, 0, 0, 0.2); */
  box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 5px -3px, rgba(0, 0, 0, 0.14) 0px 8px 10px 1px, rgba(0, 0, 0, 0.12) 0px 3px 14px 2px;
  border-radius: 4px;
  padding: 0 0 12px 0;
  width: ${(props) => props.w ? props.w + "px" : "auto"};
  overflow: hidden;
  ul {
    padding: 0;
    margin: 0;
    list-style: none;
    max-height: 200px;
    overflow-y: scroll;

    ::-webkit-scrollbar {
      width: 4px;
    }
    /* Track */
    ::-webkit-scrollbar-track {
      background: transparent;
    }
    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: rgba(0, 0, 0, 0.15);
      border-radius: 4px;
    }
    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: rgba(0, 0, 0, 0.2);
    }
    li {
      cursor: pointer;
      padding: 0;
      &:hover,
      &.selected {
        background-color: ${(props) => props.theme.color.darkBlue};
        color: white;
      }
    }
  }
  ${props => props.isValStr && css`
    ul li {
      padding: 10px 14px;
      color: ${props => props.theme.color.gray700}
    }
  `}
  .search-input {
    background-color: ${props => props.theme.color.gray50};
    height: 40px;
    outline: none;
    border-radius: none;
    margin-bottom: 10px;
    border-bottom: ${props => props.theme.color.gray300} 1px solid;
    font-size: 15px;
    font-weight: 300;
    padding-left: 14px;
    border-radius: 0;
  }
`;

function searchComponenets(components, term) {
  return components.filter((item) => ReactDOMServer.renderToStaticMarkup(item.name)
      .replace(/<\/?[^>]+(>|$)/g, "")
      .trim()
      ?.toLowerCase()
      .startsWith(term.toLowerCase())
  );
}

function Options({
  data,
  selected,
  term,
  handleInput,
  onSelect,
  w,
  multi,
  search,
}) {
  if(!data.length) return null;
  
  let optionsList = data;

  if (search && term !== "") optionsList = searchComponenets(optionsList, term);

  if(!optionsList.length) optionsList = data;

  optionsList = optionsList
    .sort((a, b) => {
      if (!multi) return 0;
      if (selected.map((item) => item.value).includes(a.value)) return -1;
      else return 1;
    })
    .map((item, i) => {
      if (!data.length) return "";

      return (
        <li
          onClick={() => onSelect(item)}
          className={
            selected.map((s) => s.value).includes(item.value) ? "selected" : ""
          }
          key={i}
        >
          {item.name || ""}
        </li>
      );
    });

  return (
    <StyledOptions w={w} className="options" isValStr={typeof data[0].name === 'string'}>
      {search && (
        <Input
          placeholder="Type to search"
          value={term}
          onInput={handleInput}
          className="search-input"
        />
      )}
      <ul>{optionsList}</ul>
    </StyledOptions>
  );
}

export default Options;
