// *** React
import React from "react";
import Approach from "./Approach/Approach";
import Values from "./Values/Values";
import WeDo from "./WeDo/WeDo";
import WeOffer from "./WeOffer/WeOffer";
import CaseStudies from "./CaseStudies/CaseStudies";
import Hero from "./Hero/Hero";
import NavHeader from "./NavHeader";
import Nav from "./Hero/Nav";
import OurClints from "./OurClints/OurClints"
import "./home.scss"
import MainTitle from "../MainTitle";
import { Link } from "react-router-dom";
import logo1 from "../../assets/Stitchain 1.svg"
import logo2 from "../../assets/stitvest 1.svg"
import logo3 from "../../assets/ibn alhaythem 1.svg"
import logo4 from "../../assets/aws 1.svg"
import logo5 from "../../assets/liban call l.svg"

// *** Styled Components

// *** Components

function HomeContainer() {
  const leftLogos = [
    { src: logo1,link:"https://www.stitchain.com/" },
   
    { src: logo3,link:"http://www.ibn-alhaytham.com/" },
    { src: logo4,link:"https://aws.amazon.com/" },
    { src: logo5,link:"http://www.libancallplus.com/" },
    { src: logo2,link:"https://www.stitvest.com/" },

  ];
  return <>
    <Hero />
   < div className="main">
  {/* <NavHeader/> */}

   <WeDo/>
   <div id="weOffer">
   <WeOffer name="weOffer"/>
   </div>
  
   <Values/>  
    <Approach/>
    
   
     <CaseStudies/> 
     {/* <OurClints/> */}
     <MainTitle
          fTitle="Our"
          sTitle="Partners"
          subtitle="From startups to fortune 500 companies,
          we create custom solutions that grow brands online"
         
          details="" 
        />
    </div>
    <div className="logoslider" style={{marginTop:"-40px"}}>
        <div class="logos">
          <div class="logo_items">
          {/* <Row lg={leftLogos.length} md={4} xs={2} sm={3} >
            {leftLogos.map((logo) => (
         
            <Col><Link to={logo.link} target="_blank"><img style={{width:"75px",height:"75px"}} src={logo.src} /></Link> </Col>
               
            
            ))}

              </Row> */}
{
leftLogos.map((logo) => (
         
         <Link className="linklogo" to={logo.link} target="_blank"><img   src={logo.src} /></Link>    ))}
          </div>
        </div>

        {/* <div class="logos">
          <div class="logo_items2">
            {leftLogos.map((logo) => (
              <>
                <img src={logo.src} />
              </>
            ))}
          </div>
          
        </div> */}
      </div>
  
    </>
  
}

export default HomeContainer;
