import React from "react";
import "./styles/customprojects.scss";
import { Col, Row } from "react-bootstrap";
import placeholder from "../../assets/projects/placeholder.svg";
import { useEffect } from "react";
// import img1 from "../../assets/projectsi/Phone_Long_Scroll_Mockup_v01 copy.jpg";

// import img2 from "../../assets/projectsi/tobsir poster copy (1).jpg";
// import img3 from "../../assets/projectsi/smart wave poster copy.jpg";
// import img4 from "../../assets/projectsi/med hub poster CO (1).jpg";
import img1 from "../../assets/caseStudies/LibanCall.jpg";

import img2 from "../../assets/caseStudies/tobsir poster copy.jpg";
import img3 from "../../assets/caseStudies/smart wave poster copy.jpg";
import img4 from "../../assets/caseStudies/med hub poster CO.jpg";

import { useHnndes } from "view/provider";
import { Flex, Pagination} from "../../ui";


// function CustomProjects({
//   selectedGroup,
//   count,
//   setCount,
//   currentPage,
//   setCorrentPage,
//   page,
//   setPage,
//   setProjectCount,
// }) {
  function CustomProjects(){
  const {
    state: { plist:projects,totalSize,perPage,page}, dispatch,

    fetchProjects: fetchPros,
  } = useHnndes();

  useEffect(() => {
    fetchPros("1;2",page,perPage);
    console.log("projects",projects)
   
  }, [fetchPros]);
  const handleChangePage = (newPage) =>
    dispatch({ type: "set_page", payload: newPage });
  // const projects = [
  //   {
  //     id: 0,
  //     img:img1,
  //     title: "Telecom Solution Development",
  //     group: 1,
  //     tools: [{ name: "Mobile App" }, { name: "Website" }],
  //   },
  //   {
  //     id: 1,
  //     img:img3,
  //     title: "Smart Wave",
  //     group: 2,
  //     tools: [{ name: "Website" }],
  //   },
  //   {
  //     id: 2,
  //     img: img2,
  //     title: "Tobsir",
  //     group: 1,
  //     tools: [{ name: "Mobile App" }, { name: "Website" }],
  //   },
  //   {
  //     id: 3,
  //     img: img4,
  //     title: "Medhub",
  //     group: 1,
  //     tools: [{ name: "Website" }],
  //   },
 
   
  // ];
  // useEffect(() => {
  //   if (selectedGroup != 0)
  //     setCount(
  //       Math.ceil(projects.filter((p) => p.group == selectedGroup).length / 4)
  //     );
  //   else setCount(Math.ceil(projects.length / 4));
  // }, [page, selectedGroup]);

  // useEffect(() => {
  //   setPage(0);
  // }, [selectedGroup]);
  // useEffect(() => {
  //   let temp = [];
  //   if (selectedGroup != 0)
  //     temp = projects.filter((p) => p.group == selectedGroup);
  //   else temp = projects;
  //   setProjectCount(temp.length);

  //   setCorrentPage(
  //     temp.slice((page == 0 ? 0 : page - 1) * 4, page == 0 ? 4 : page * 4)
  //   );
  // }, [page, selectedGroup]);

  // if (currentPage.length == 0)
  //   return <div className="nodata">No Data Found</div>;

  return (
    <div className="CustomProjects">
      <Row  lg="2" md="1" sm="1" xs="1"  >
        {/* {currentPage
          .filter((project) =>
            selectedGroup != 0 ? project.group === selectedGroup : project
          ) */}
         { projects?.map(({},i)=>(
            <Col  className="" >
              <div className="card">
                <div>
                  <img
                  style={{borderRadius:"10px"}}
                    src={projects[i]["imageUrl"]}
                    alt={projects[i]["projectName"]}
                   width="100%"
                   height="100%"/>
                </div>
                <div className="titlepro">
                  <p>{projects[i]["projectName"]}</p>
                  {projects[i]["tagList"]?.map((tool) => (
                    <span >{tool}</span>
                  ))}
                </div>
              </div>
            </Col>
          ))}
            <Flex style={{justifyContent:"flex-end",height:"100px",
    width:"100%"}}>
      {/* <Pagination
        data-testid="pagination"
     count={totalSize}
       page={page}
      rowsPerPage={perPage}
    onPageChange={handleChangePage}
      /> */}
      <Pagination
              sx={{ mt: 20 }}
              data-testid="product-pagination"
              count={6}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={perPage}
              //setPage={setPage}
            /> 
   </Flex>
      </Row>
    
    </div>
  );
}

export default CustomProjects;
