import React from "react";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
// import wlogo from "../../assets/logoooooo.svg";
import wlogo from "../../assets/finalblogo.svg";
import wlogo1 from "../../assets/finalwlogo.svg"
import closesvg from "../../assets/closesvg.svg";
import { styled } from "styled-components";
import "./NavHeader.scss"
import menu from "../../assets/ph_list1.svg";
import { HashLink, NavHashLink } from 'react-router-hash-link'; // <-- import link component
const StyledNav = styled.div`
  color: black;

`;

function NavHeader() {
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  let navigate = useNavigate();

  return (
    <div className="navheader">
      <div className="logo1">
        <img
          src={wlogo}
          width="100%"
          height="100%"
          alt="v"
          style={{cursor:"pointer"}}
          onClick={() => navigate("/")}
        />
      </div>
      <div className="navLink"> 
<Link className="navl"   to="/">
              Home
            </Link>
           <NavHashLink className="navl"  to="/#weOffer"  smooth>
            Services
            </NavHashLink>
           <Link className="navl"  to="/projects">
            Portfolio
            </Link>
           <Link className="navl"  to="/about">
            About
            </Link>
            <Link className="navl"  to="/news"  >
           Events & News
            </Link>
          
</div>
<Link className="contactBtn" to="/contact">Contact Us</Link>
      <div className="menu1" onClick={() => setShow(true)}>
        <img src={menu} width="80%" height="80%" alt="v" />
      </div>
      <Modal show={show} onHide={handleClose} fullscreen={true}>
        <Modal.Body>
          <img
            alt=""
            src={wlogo1}
            className="d-inline-block align-top logo1"
          />

          <img
            alt=""
            src={closesvg}
            className="d-inline-block close1"
            onClick={handleClose}
          />

          <div
            id="1"
            aria-label="a1"
            className="d-flex justify-content-center title mt-5"
        
          >
            <Link  aria-label="a1" className="custom-nav-link" to="/">
              Home
            </Link>
          </div>

          <div
            id="2"
            aria-label="a2"
            className="d-flex justify-content-center title"
          >
          <HashLink aria-label="a2" className="custom-nav-link" to="/#weOffer"  > Services</HashLink>
          </div>

          <div
            id="3"
            aria-label="a3"
            className="d-flex justify-content-center title"
          >
            <Link aria-label="a3" className="custom-nav-link" to="/projects">
              Portfolio
            </Link>
          </div>

          <div
            id="4"
            aria-label="a4"
            className="d-flex justify-content-center title"
          >
            <Link aria-label="a4" className="custom-nav-link" to="/about">
              About
            </Link>
          </div>

          <div
            id="5"
            aria-label="a5"
            className="d-flex justify-content-center title"
          >
            <Link aria-label="a5" className="custom-nav-link" to="/contact">
              Contact Us
            </Link>
          </div>
          <div
            id="6"
           
            className="d-flex justify-content-center title"
          >
            <Link  className="custom-nav-link" to="/news">
            New & events
            </Link>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default NavHeader;
