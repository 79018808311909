import { Route } from "react-router-dom";



import Soon from "./Soon";

export default (
  <>
    <Route
      path="/soon"
      exact
      element={
    
          <Soon />
      
      }
    >
      <Route
        index
        element={
       
            <Soon />
       
        }
      />
    </Route>
  </>
);
