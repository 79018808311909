import React, { useEffect, useRef, useState } from 'react'
import NavHeader from 'view/home/NavHeader'
import  "./news.css"
import { useLocation, useParams } from 'react-router-dom';
import { useHnndes } from 'view/provider';
import ReactQuill from 'react-quill';
function NewsDetails({ route, navigation }) {
  const location = useLocation();
//   const {state} = useLocation();
// const {id} = state;
//const {params}=useParams();
let { id } = useParams();
  const {
    state: { selected:article},fetchArticleInfo
  } = useHnndes();
  useEffect(() => {
    console.log("id","title")
   
  }, []);
  useEffect(() => {
    fetchArticleInfo(id,1)
   
  }, [fetchArticleInfo]);
 
  const [copied, setCopied] = useState(false);

  function copy() {
    const el = document.createElement("input");
    el.value = window.location.href;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    setCopied(true);
  }
  const [hover, setHover] = useState(false);

  const HoverData = "Click to copy url";
  const onHover = (e) => {
    e.preventDefault();
    setHover(true);
    console.log("hovered");
  };

  const onHoverOver = (e) => {
    e.preventDefault();
    setHover(false);
  };
  return (
    <div>
      
        <NavHeader />
        <div className="mainNews">
        <div
              className="blog-img2"
            
            >
              
              <img   onMouseEnter={(e) => onHover(e)}
            onMouseLeave={(e) => onHoverOver(e)} src={article?.imageUrl} alt="" onClick={copy} />
             {hover && <p className={hover}>{HoverData}</p>}
            
            </div>
           
                <p className="blog-title2">{article?.title}
{/* <button onClick={copy}>{!copied ? "Copy link" : "Copied!"}</button>  */}
                </p>
                <p className="blog-detail2">
                
                <ReactQuill
   value={article?.content}
   readOnly={true}
  theme={"bubble"}
/>
                </p>


            </div>
           
    </div>
  )
}

export default NewsDetails
