import { Route } from "react-router-dom";



import Projects from "./Projects";
import ProjectsContainer from "./ProjectsContainer";

export default (
  <>
    <Route
      path="/projects"
      exact
      element={
        // <RequireAuth>
          <Projects />
        // </RequireAuth>
      }
    >
      <Route
        index
        element={
          // <RequireAuth>
            <ProjectsContainer />
          // </RequireAuth>
        }
      />
    </Route>
  </>
);
