import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Dropdown from "../core/Dropdown";
import Label from "../Label";
import Type from "../Type";
import Options from "./Options";
import Selected from "./Selected";

const Wrapper = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;
  .msg {
    color: ${(props) => props.theme.color.gray600};
    margin-top: 2px;
    font-size: 12px;
    /* margin-left: 10px; */
  }
  &.error {
    .msg {
      color: ${(props) => props.theme.color.red};
      /* margin: 4px 0 0 0;
      line-height: 24px; */
    }
  }
`;

function Select({
  data = [],
  mapData,
  label = "",
  name = "",
  onChange = () => {},
  inputProps,
  inputStyle,
  msg = null,
  error = false,
  defaultValue,
  disabled = false,
  multi = false,
  search = true,
  size,
  noSeperator=true,
  ...rest
}) {
  const [selected, setSelected] = useState([{ name: `${name}` }]);
  const [open, setOpen] = useState(0);
  const [w, setW] = useState(0);
  const [term, setTerm] = useState("");
  const selectedEl = useRef(null);
  const mappedData = useRef(null);

  mappedData.current = data.map((item) => ({
    name: mapData ? item[mapData.label] : item,
    value: mapData ? item[mapData.value] : item,
    data: item,
  }));

  useEffect(() => {
    setW(selectedEl.current.offsetWidth);
  }, []);

  const handleInput = (e) => {
    setTerm(e.target.value);
  };

  const onSelect = (item) => {
    setSelected((s) => {
      let a = s;
      if (s.length && !s[0].hasOwnProperty("data")) a.shift();
      if (!multi) return [item];
      if (a.map((i) => i.value).includes(item.value)) {
        return a.filter((i) => i.value !== item.value);
      } else return [...a, item];
    });
    setOpen(0);
    setTerm("");
  };

  useEffect(() => {
    if (!(selected.length && selected[0].hasOwnProperty("data"))) return;
    onChange(multi ? selected : selected[0], inputProps?.name);
  }, [selected, multi, onChange, inputProps?.name]);

  useEffect(() => {
    if (typeof defaultValue !== 'undefined') {
      setSelected(
        mappedData.current.filter((item) => {
          let values = defaultValue;
          if (!multi) values = [defaultValue];
          return values?.includes(item.value);
        })
      );
    }
  }, [defaultValue, multi]);

  return (
    <Wrapper className={`custom-select ${error ? "error" : ""}`} {...rest}>
      {Boolean(label.length) && (
        <Label htmlFor={label.replace(/\s/g, "-").toLowerCase()}>{label}</Label>
      )}

      <Dropdown
        space={5}
        content={
          <Options
            {...{
              data: mappedData.current,
              selected,
              onSelect,
              term,
              w,
              multi,
              search,
              handleInput,
            }}
          />
        }
        show={open}
        setShow={setOpen}
      >
        <span>
          <Selected
            {...{
              open,
              label,
              selected,
              query: term,
              disabled,
              search,
              noSeperator,
              style: inputStyle,
              size,
            }}
            refEl={selectedEl}
          />
          <input {...{onChange: () => {}, ...inputProps}} className="visually-hidden" />
        </span>
      </Dropdown>
      {Boolean(msg) && <Type className="msg">{msg}</Type>}
    </Wrapper>
  );
}

export default Select;
