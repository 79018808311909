const theme = {
  color: {
    primary: "#833dcc",
    secondary: "#132851",
    lightBlue: "#199AC5",
    dark: "#212529",
    dark1: "#4F4F6F",
    dark2: "#acacac",
    dark3: "#0E0E0E",
    dark4: "#222222",
    white: "#FFF",
    danger: "#B62121",
    success: "#21B639",
    gray0: "#FAFAFA",
    gray1: "#47225E",
    gray2: "#838383",
    gray3: "#999999",
    gray4: "#97AABD",
    gray5: "#EBEBEB",
    gray6: "#AAAAAA",
    gray7: "#f5f5f5",
    gray8: "#666",
    gray9: "#e3e3e3",
    gray10: "#373532",
    gray11: "#4c4c4c",
    grayOpacity1: "#37353224",
    background1: "#4d2d6e",
    blue: "#4267b2",
    red: "#db4437",
    black1: "#000",
    black2: "#262626",
    black3: "#333333",
    black22: "#222",
    green: "green",
    orange: "orange",
  },
  fonts: {
    Poppins: "Poppins, Roboto , sans-serif",
    size: 14,
    arabicBodyLight: "GE SS Two Light",
    arabicBodyMedium: "GE SS Two Medium",
  },
  palette: {
    mode: "light",
  },
};

export default theme;
