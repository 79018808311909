import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import menu from "../../assets/img/menu.svg";
import logo2 from "../../assets/img/hnndesLogo.svg";
import circle from "../../assets/img/gradient-circle.svg";
import rechome from "../../assets/img/gradient-circle.svg";
import circledcircle from "../../assets/img/lines-Circle.svg";
import bg from "../../assets/img/HomePageBG.svg";
import './hero.scss'
function Soon() {
	return (
		<div
			className="Hero "
			id="d1"
			style={{ height: "100vh", width: "100%", backgroundColor: "#000000" }}
		>
			<>
				<Row>
					<Col xs="12" md="12" className="mt-5" >
						<div style={{ width: "240px", height: "240px", margin: "auto" }}>
							<img src={logo2} style={{ width: "100%", height: "100%" }} />
						</div>
						<div className="text-div centerd ">
							<div className="circle2">
								<img src={circle} alt="" width="100%" height="100%" />
							</div>
							<p className=" hero-text text-bold" >
								<span
									className="d-block bolkfont fadding-word"
									style={{ paddingLeft: "113px" }}
								>
									Comming
									<span className="main-color bolkfont fadding-word-2">
										{" "}
										Soon{" "}
									</span>
								</span>
							</p>
							{/* <div className="circle3 animation-right-buttom">
								<img src={circledcircle} alt="" width="100%" height="100%" />
							</div> */}
						</div>
						<div className="circle animation-right-buttom">
							<img src={circle} alt="" width="100%" height="100%" />
						</div>
					</Col>
				</Row>
			</>
			{/* <div className="circle4">
				<img src={circle} alt="" width="100%" height="100%" />
			</div> */}
		</div>
	);
}

export default Soon;
