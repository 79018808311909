import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import "@fortawesome/fontawesome-free/css/all.min.css";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import styled from "styled-components";

import MainTitle from "../../MainTitle";
import './approach.scss'


const StyledApproach = styled.div`



`;

function Approach() {



    const bgc='linear-gradient(to bottom,rgba(127, 245, 147, 1),rgba(104, 234, 174, 0.5), rgba(83, 223, 199, 0.5))';
    const [isHovered, setIsHovered] = useState(null)
    const [id, setID] = useState("0")
    const[click,setClick]=useState(false)
    const[cube,setCube]=useState("cube hidebf");
    const handleMouseOver = (e) => {
        switch (e.target.id) {
            case "1":
               
                setClick(true)
                setID("1")
             setCube("cube1 hidebf")
                break
            case "2":
              setClick(true)
                setID("2")
                setCube("cube2 hidebf")
                break
                case "3":
                  setClick(true)
                 setID("3")
                 setCube("cube3 hidebf")
                 break
             case "4":
              setClick(true)
                 setID("4")
                 setCube("cube4 hidebf")
                 break
                 case "5":
                  setClick(true)
                   setID("5")
                   setCube("cube5 hidebf")
                   break
            
               
        }
    }
    
     return (
      <StyledApproach>

     
       <MainTitle fTitle="Our" sTitle="Approach"
  subtitle="BY THE 5 PRINCIPLES OF SUCCESS" 
  details="To provide the best digital services we work tirelessly on every project to meet the specific needs of our clients."
  />
         <Row className="approachRow">
           <Col xs={6} md={4} className="col13Padding" >
           <div  className="unDefindepartment">
           <p className="divP" id="1" onMouseOver={handleMouseOver}   onMouseLeave={() => setID("0")}>
   1.Design
   </p>
  <p className="pDetails" id="1" onMouseOver={handleMouseOver}   onMouseLeave={() => setID("0")}>
   Plan the future state of capabilities and chart a path to get there.
   </p>
             </div>
          
           </Col>
           <Col xs={6} md={4} >
             
   
      <div class="container1">
        {/* <div class="cube hidebf"> */}
        <div class={cube}>
          <div class={id==="2"?"face frontVisible":"face front"}><span className="content" >Diagnose</span></div>
          <div class="face back"><span className="content" >Deliver</span></div>
          <div class={id==="5"?"face rightVisible":"face right"}><span className="content" >Drive</span></div>
          <div class={id==="1"?"face leftVisible":"face left"}><span className="content" >Design</span></div>
          <div class={id==="3"?"face topVisible":"face top"}><span className="content" >Develop</span></div>
          <div class={id==="4"?"face bottomVisible":"face bottom"}><span className="content" >Deliver</span></div>
        </div>
      </div>
      
     
 

               </Col>
           <Col xs={6} md={4} className="colPadding"     >
           <div  className="unDefindepartment">
           <p className="divP" id="2" onMouseOver={handleMouseOver} onMouseLeave={() => setID("0")}>
   2.Diagnose
   </p>
  <p className="pDetails" id="2" onMouseOver={handleMouseOver} onMouseLeave={() => setID("0")}>
   Determine current capabilities and define the business need.
   </p></div>
   
           </Col>
         </Row>
        
         <Row className="deRow">
           <Col xs={6} md={4}  className="col13Padding" >
           <div  className="unDefindepartment">
           <p className="divP" id="3" onMouseOver={handleMouseOver}   onMouseLeave={() => setID("0")}>
   3.Develop
   </p>
 <p className="pDetails" id="3" onMouseOver={handleMouseOver}   onMouseLeave={() => setID("0")}>
   Create a learning experience that works.
   </p>
   </div>
           </Col>
           <Col xs={6} md={4}></Col>
           <Col xs={6} md={4} className="colPadding" >
           <div  className="unDefindepartment">
           <p className="divP" id="4" onMouseOver={handleMouseOver}   onMouseLeave={() => setID("0")}>
           4.Deliver
   </p>
 <p className="pDetails" id="4" onMouseOver={handleMouseOver}   onMouseLeave={() => setID("0")}>
 Determined to exceed client expectations with reliable project management.
   </p>
   </div>
           </Col>
         </Row>
   
         <Row className="deRowL">
         
         <Col xs={6} md={12} className="lastCol"  >
         <div  className="unDefindepartment">
           <p className="divP" id="5" onMouseOver={handleMouseOver}   onMouseLeave={() => setID("0")}>
   5.Drive
   </p>
  <p className="pDetails" id="5" onMouseOver={handleMouseOver}   onMouseLeave={() => setID("0")}>
   Ensure solutions continuously create impact.
   </p>
   </div></Col>
  
         </Row>
    
               
               </StyledApproach>
     );
}

export default Approach
