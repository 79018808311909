import React, { useEffect, useState } from 'react'
import Col from 'react-bootstrap/esm/Col'
import Row from 'react-bootstrap/esm/Row'
import open from '../../../assets/values/open 1.svg'
import caring from '../../../assets/values/care 1.svg';

import smart from '../../../assets/values/smart 1.svg'
import flexible from '../../../assets/values/FLEXIBLE 1.svg'
import EFFICIENT from '../../../assets/values/EFFICIENT 1.svg'
import ValuesCard from './ValuesCard'
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import styled from "styled-components";
import MainTitle from '../../MainTitle'

const StyledValues= styled.div`
.main{
  max-width: 1200px ;
  position: relative;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  overflow-x: hidden;
 
  
}
.weAre{
  width:160px;
  margin-top:10px;
  margin-left:65px;
 
  @media screen and (max-width:767px){
      
    margin-left:40px;
 
 
   }
  @media screen and (max-width:474px){
      
   margin-left:10px;


  }

}

`;
const cardVariants={
  offscreen: {
  
    x:-300,
    opacity: 0
  },
  onscreen: {

    x:0,
    opacity: 1,
    transition: {
      ease: "easeIn",
      bounce: 0.4,
      duration: 1
      ,delay:0.1
    }
  }
};

const cardVariants1={
  offscreen: {
  
    x:-200,
    opacity: 0
  },
  onscreen: {

    x:0,
    opacity: 1,
    transition: {
      ease: "easeIn",
      bounce: 0.4,
      duration: 1
      ,delay:0.2
    }
  }
};
const cardVariants2={
  offscreen: {
  
    x:200,
    opacity: 0
  },
  onscreen: {

    x:0,
    opacity: 1,
    transition: {
      ease: "easeIn",
      bounce: 0.4,
      duration: 1
      ,delay:0.3
    }
  }
};
const cardVariants3={
  offscreen: {
  
    x:200,
    opacity: 0
  },
  onscreen: {

    x:0,
    opacity: 1,
    transition: {
      ease: "easeIn",
      bounce: 0.4,
      duration: 1
      ,delay:0.4
    }
  }
};
const cardVariants4={
  offscreen: {
  
    x:-200,
    opacity: 0
  },
  onscreen: {

    x:0,
    opacity: 1,
    transition: {
      ease: "easeIn",
      bounce: 0.4,
      duration: 1
      ,delay:0.4
    }
  }
};
const cardVariants5={
  offscreen: {
  
    x:200,
    opacity: 0
  },
  onscreen: {

    x:0,
    opacity: 1,
    transition: {
      ease: "easeIn",
      bounce: 0.4,
      duration: 1
      ,delay:0.5
    }
  }
};
function Values() {

  return (
    <StyledValues>

       <MainTitle fTitle="Our" sTitle="VALUES"
  subtitle="WHY CHOOSE HNNDES FOR YOUR BUSINESS" 
  details="   Our important values
  In the fast-paced IT and software development industry,
 providing everything the customer needs in terms of quality, security, flexibility and openness"
  />
<Row>
<Col md="4">
<motion.div
     
      variants={cardVariants}
      initial="offscreen"
      whileInView="onscreen"
      viewport={{ once: true }}
  
    >
<ValuesCard  
img={open}
title="OPEN"
content="Our work processes and decision-making are transparent, and all our achievements result from collaborative efforts. We’re always attentive to our customers’ and employees’ ideas. We're equally open to innovations, so if there's a better way to do something - let's do it!"

/>
</motion.div>
</Col>
<Col md="4">
<motion.div
     
      variants={cardVariants2}
      initial="offscreen"
      whileInView="onscreen"
      viewport={{ once: true }}
  
    >
<ValuesCard 
img={caring}
title="CARING"
content="We care about our customers, our teammates, and the products that we develop. We’re passionate about our work and dedicated to building long-term business relationships based on trust and respect."

/>
</motion.div>
</Col>
<Col md="4">
<motion.div
     
      variants={cardVariants3}
      initial="offscreen"
      whileInView="onscreen"
      viewport={{ once: true }}
  
    >
<ValuesCard 
img={smart}
title="SMART"
content="In the fast-paced IT & software development industry, we’re driven by common sense and eagerness to learn new technologies, domains and perspectives. Intelligence as the ability to acquire and apply new skills is our instrumental value for growing a truly talented team."

/>
</motion.div>
</Col>
</Row>
<Row>
<Col md="4">
<motion.div
     
      variants={cardVariants4}
      initial="offscreen"
      whileInView="onscreen"
      viewport={{ once: true }}
  
    >
<ValuesCard 
whileHover={{backgroundColor:"hsla(130, 86%, 73%, 1)",cursor:"pointer"}}
img={flexible}
title="FLEXIBLE"
content="We’re agile in our work and software development processes. Our customer focus means we stay adaptable and ready to customize our approach to help our customers and employees achieve their goals."

/>
</motion.div>
</Col>
<Col md="4">
<motion.div
     
      variants={cardVariants1}
      initial="offscreen"
      whileInView="onscreen"
      viewport={{ once: true }}
  
    >
<ValuesCard 
img={EFFICIENT}
title="EFFICIENT"
content="We’re professionals: committed, well-organized, and well-prepared in our work. Our focus on quality processes and quality end products means we always plan, implement, test, and improve, thus ensuring reliability and repeatability."

/>
</motion.div>
</Col>
<Col md="4">

<motion.div className='weAre'
     
     variants={cardVariants5}
     initial="offscreen"
     whileInView="onscreen"
     viewport={{ once: true }}
 
   >
<p style={{color:"rgba(127, 245, 147, 1)",fontSize:"23px",fontWeight:'bold',marginTop:"30px"}}>
WE ARE
</p>
<p style={{color:"black",fontSize:"25px",fontWeight:700,wordBreak:" ",marginTop:"-10px"}}>
SMART CARING OPEN FLEXIBLE EEFFICIENT
</p>
</motion.div>
</Col>

</Row>

        </StyledValues>
  )
}

export default Values
