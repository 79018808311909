import React from "react";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
// import darklogo from "../../../assets/darklogoo.svg";
import darklogo from "../../../assets/finalwlogo.svg";
import darklogoMob from "../../../assets/hnndes logo finalMob1.svg";
import closesvg from "../../../assets/closesvg.svg";

import menu from "../../../assets/ph_list.svg";
import "./styles/nav.scss";
import { HashLink } from "react-router-hash-link";

function Nav() {
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  let navigate = useNavigate();

  return (
    <>
      <div className="logo">
        <img
          src={darklogo}
          width="100%"
          height="100%"
          alt="v"
          onClick={() => navigate("/")}
        />
      </div>
      <div className="logoMob" >
        <img
          src={darklogoMob}
          width="100%"
          height="100%"
          alt="v"
          onClick={() => navigate("/")}
        />
      </div>
<div className="navLink"> 
<Link className="navl1"   to="/">
              Home
            </Link>
           <HashLink className="navl1"  to="/#weOffer" smooth>
            Services
            </HashLink>
           <Link className="navl1"  to="/projects">
            Portfolio
            </Link>
           <Link className="navl1"  to="/about">
            About
            </Link>
            <Link className="navl1"  to="/news"  >
           Events & News
            </Link>
          
</div>
<Link className="contactBtn" to="/contact">Contact Us</Link>
      <div className="menu" onClick={() => setShow(true)}>
        <img src={menu} alt="v" className="imgMenuu" />
      </div>
      <Modal show={show} onHide={handleClose} fullscreen={true}>
        <Modal.Body>
          <img
            alt=""
            src={darklogo}
            className="d-inline-block align-top logo"
          />

          <img
            alt=""
            src={closesvg}
            className="d-inline-block close"
            onClick={handleClose}
          />

          <div
            id="1"
            aria-label="a1"
            className="d-flex justify-content-center title mt-5"
          >
            <Link aria-label="a1" className="custom-nav-link" to="/" onClick={(e)=>setShow(false)}>
              Home
            </Link>
          </div>

          <div
            id="2"
            aria-label="a2"
            className="d-flex justify-content-center title"
          >
          
            <HashLink aria-label="a2" className="custom-nav-link" to="/#weOffer" onClick={(e)=>setShow(false)}> Services</HashLink>
            
          </div>

          <div
            id="3"
            aria-label="a3"
            className="d-flex justify-content-center title"
          >
            <Link aria-label="a3" className="custom-nav-link" to="/projects">
              Portfolio
            </Link>
          </div>

          <div
            id="4"
            aria-label="a4"
            className="d-flex justify-content-center title"
          >
            <Link aria-label="a4" className="custom-nav-link" to="/about">
              About
            </Link>
          </div>

          <div
            id="5"
            aria-label="a5"
            className="d-flex justify-content-center title"
          >
            <Link aria-label="a5" className="custom-nav-link" to="/contact">
              Contact Us
            </Link>
            </div>
            <div
            id="6"
           
            className="d-flex justify-content-center title"
          >
            <Link  className="custom-nav-link" to="/news">
            New & events
            </Link>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Nav;
