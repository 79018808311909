import React, { useEffect } from 'react'
import styled from "styled-components";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import logo from "../../../assets/libancallLogo.svg";
import logo1 from "../../../assets/tobsirLogo.svg";
import logo2 from "../../../assets/smartWavelogo.svg";
import logo3 from "../../../assets/medhubLogo.svg";
import logo4 from "../../../assets/decorationlogo.svg";
import logo5 from "../../../assets/careLogo.svg";
import img1 from "../../../assets/caseStudies/LibanCall.jpg";

import img2 from "../../../assets/caseStudies/tobsir poster copy.jpg";
import img3 from "../../../assets/caseStudies/smart wave poster copy.jpg";
import img4 from "../../../assets/caseStudies/med hub poster CO.jpg";
//import studybtn from "../../../assets/studybutton.svg";
//import studygbtn from "../../../assets/studyGray.svg";
import studygbtn from "../../../assets/lgraycircle.svg";
import studybtn from "../../../assets/lgreenCircle.svg";
import { useState } from 'react';
import {motion} from 'framer-motion'
import MainTitle from '../../MainTitle';
import { Link } from 'react-router-dom';
import "./studies.scss";
import { useHnndes } from 'view/provider';

const StyledCase = styled.div`
@media screen and (max-width:474px){
    margin-top:-35px;
}
button{
        height: 15px;
        width: 15px;
        background-color:rgba(0, 0, 0, 0.5);
        border-radius: 50%;
        display: inline-block;
        border:none;
        padding:5px;
        margin:5px;
        @media screen and (max-width:1200px){
          height: 15px;
          width: 15px;
          padding:5px;
          margin:5px;
        }
        @media screen and (max-width:991px){
          height: 13px;
          width: 13px;
          padding:4px;
          margin:4px;
        }
        @media screen and (max-width:767px){
          height: 12px;
          width: 12px;
          padding:3px;
          margin:3px;
        }
        @media screen and (max-width:474px){
        height: 11px;
          width: 11px;
          padding:0px;
          margin:8px;
        }
      }
     
.pName{
  font-weight:600;font-size:23px;color:black;font-family:Poppins;  margin-bottom:10px;display:inline-block;
  @media screen and (max-width:1200px){
    font-weight:650;
    font-size:22px;
  
  }
  @media screen and (max-width:991px){
    font-weight:600;
    font-size:21px;
   
  }
  @media screen and (max-width:767px){
    font-weight:550;
    font-size:20px;
   
  }
  @media screen and (max-width:474px){
    font-weight:500;
    font-size:19px;
   
  }
}

.pType{
font-weight:500;font-size:22px;color:black;font-family:Poppins;
margin-top:18px;
display: inline-block;

@media screen and (max-width:1200px){
  font-weight:350;
  font-size:18px;

}
@media screen and (max-width:991px){
  font-weight:400;
  font-size:17px;
  
}
@media screen and (max-width:767px){
  font-weight:400;
  font-size:17px;
 
}
@media screen and (max-width:474px){
  font-weight:400;
  font-size:17px;
  
}
}

`;

function CaseStudies() {
    
    const[id,setId]=useState("1")
    const handleClick=(e)=>{
       
        setId(e.target.id);
        console.log("d",id);
       
      
    }
    const {
      state: { plist:Projects},
      
      fetchProjects: fetchProj,
      
    } = useHnndes();
  
     useEffect(() => {
  

  fetchProj("1;2")

    }, [fetchProj]);
    useEffect(() => {
  
  console.log(Projects,"projectsss")
    
    }, [Projects]);
    const fifthProjects=Projects?.slice(0,5);
 
    // const projects=[
    //     {pID:0,pName:"Telecom Solution Development",pDetails:"Libancall Plus is the leading provider of telecommunication and value-added services and solutions in the MENA Region.mation",
    //     pLogo:logo,pImage:img1,pType:"Mobile App"},
    //     {pID:1,pName:"Smart Wave",pDetails:"Elevate your online presence with customized web and app solutions, API integration, software development, and cybersecurity expertise.",
    //     pLogo:logo2,pImage:img3,pType:"Website"},
    //     {pID:2,pName:"Tobsir",pDetails:"An all-in-one platform offering Coffee reading, Dream interpretation, Palm reading, Soulmate,Tarot, Spiritual reading and horoscopes.",
    //     pLogo:logo1,pImage:img2,pType:"Website & Mobile App"},
    //     {pID:3,pName:"Medhub",pDetails:"Easily create better documents and improve your business management flow. Collaborate and create anytime, anywhere. With a customization and easy-to-use drug manufacturing system.",
    //     pLogo:logo3,pImage:img4,pType:"Website"},
    //      {pID:4,pName:"Interior and exterior decorations",pDetails:"Nice Moveis is a leading design and construction company. We specialize in providing interior, exterior, repair and restoration services.",
    //     pLogo:logo4,pImage:"",pType:"Website"},
    //     {pID:5,pName:"Elderly care website",pDetails:"A peaceful haven that provides comprehensive medical services for the elderly. Personal care activities promote well-being surrounded by nature.",
    //     pLogo:logo5,pImage:"",pType:"Website"}
    //                ];
  return (
    <StyledCase>
     <MainTitle fTitle="Case"  sTitle="Studies"
subtitle="OUR CUSTOMER’S SUCCESS STORIES" 
details="We have a wallet Whether you're a small business just starting out, or a large corporation looking to revamp your online presence, our team is here to help. We are committed to providing services that help our clients achieve their goals and reach their audiences."
/>

    { id==="1" ?(
       <Row md={2} xs={1} className='studrow'>
    <Col md="6" style={{paddingTop:"10px"}}>
    <div className={id==="1" ?'animateL':undefined} >

<span className='pName' >
{/* {projects[0].pName} */}
{fifthProjects[0]?.projectName}
</span>                                                                                             
<p className='pDetails111' >{fifthProjects[0]?.description}</p>
{/* <img  className="d-inline-block align-top plogo90" src={fifthProjects[0]?.logo}/> */}
<br/>
<span className='pType'>
{fifthProjects[0]?.tagList?.map((tool) => (
                    <span >{tool}</span>
                  ))}
</span>
<br></br>
  


    </div>
    </Col>
    <Col md="6">
    <div className={id==="1" ?'animateR':undefined}    >
        <div className='pImgs'style={{backgroundImage:`url(${fifthProjects[0]?.imageUrl})`}}  ></div>
        
        </div>
        
        </Col>
</Row>
     ):id==="2"?(
      <Row className='studrow'>
      <Col md="6" style={{paddingTop:"10px"}}>
      <div  className={id==="2" ?'animateL1':undefined}   >
  
  <span className='pName' >
  {fifthProjects[1]?.projectName}
  </span>
  <p className='pDetails111' >{fifthProjects[1]?.description}</p>
  {/* <img  className="d-inline-block align-top plogo" src={projects[1].pLogo}/> */}
  <br/>
  <span className='pType'>
  {fifthProjects[1]?.tagList?.map((tool) => (
                    <span >{tool}</span>
                  ))}
  </span>
  <br></br>
  
  
      </div>
      </Col>
      <Col md="6">
      <div   className={id==="2" ?'animateR1':undefined}   >
          <div className='pImgs1'style={{backgroundImage:`url(${fifthProjects[1]?.imageUrl})`}}></div>
          
          </div>
          
          </Col>
  </Row>)
    : id==="3"?(
      <Row className='studrow'>
      <Col md="6" style={{paddingTop:"10px"}}>
      <div  className={id==="3" ?'animateL2':undefined}   >
  
  <span className='pName' >
  {fifthProjects[2]?.projectName}
  </span>
  <p className='pDetails111' >{fifthProjects[2]?.description}</p>
  {/* <img  className="d-inline-block align-top plogo75" src={projects[2].pLogo}/> */}
  <br/>
  <span className='pType'>
  {fifthProjects[2]?.tagList?.map((tool) => (
                    <span >{tool}</span>
                  ))}
  </span>
  <br></br>
  
  
      </div>
      </Col>
      <Col md="6">
      <div   className={id==="3" ?'animateR2':undefined}   >
          <div className='pImgs2' style={{backgroundImage:`url(${fifthProjects[2]?.imageUrl})`}} ></div>
          
          </div>
          
          </Col>
  </Row>)
    : id==="4"?(
      <Row className='studrow'>
      <Col md="6" style={{paddingTop:"10px"}}>
      <div  className={id==="4" ?'animateL3':undefined}   >
  
  <span className='pName' >
  {fifthProjects[3]?.projectName}
  </span>
  <p className='pDetails111' >{fifthProjects[3]?.description}</p>
  {/* <img  className="d-inline-block align-top plogo75" src={projects[3].pLogo}/> */}
  <br/>
  <span className='pType'>
  {fifthProjects[3]?.tagList?.map((tool) => (
                    <span >{tool}</span>
                  ))}
  </span>
  <br></br>
  
  
      </div>
      </Col>
      <Col md="6">
      <div   className={id==="4" ?'animateR3':undefined}   >
      <div className='pImgs3' style={{backgroundImage:`url(${fifthProjects[3]?.imageUrl})`}} ></div>
          
          </div>
        
          </Col>
  </Row>) : id==="5"?(
      <Row className='studrow'>
      <Col md="6" style={{paddingTop:"10px"}}>
      <div  className={id==="5" ?'animateL4':undefined}   >
  
  <span className='pName' >
  {fifthProjects[4]?.projectName}
  </span>
  <p className='pDetails111' >{fifthProjects[3]?.description}</p>
  {/* <img  className="d-inline-block align-top plogo10" src={projects[4].pLogo}/> */}
  <br/>
  <span className='pType'>
  {fifthProjects[4]?.tagList?.map((tool) => (
                    <span >{tool}</span>
                  ))}
  </span>
  <br></br>
  
  
      </div>
      </Col>
      <Col md="6">
      <div   className={id==="5" ?'animateR4':undefined}   >
      <div className='pImgs4' style={{backgroundImage:`url(${fifthProjects[4]?.imageUrl})`}}></div>
          
          </div>
        
          </Col>
 </Row>)
    /*: id==="6"?(
      <Row className='studrow'>
      <Col md="6" style={{paddingTop:"10px"}}>
      <div  className={id==="6" ?'animateL5':undefined}   >
  
  <span className='pName' >
  {projects[5].pName}
  </span>
  <p className='pDetails111' >{projects[5].pDetails}</p>
  <img  className="d-inline-block align-top plogo10" src={projects[5].pLogo}/>
  <br/>
  <span className='pType'>
  {projects[5].pType}
  </span>
  <br></br>
  
  
      </div>
      </Col>
      <Col md="6">
      <div   className={id==="6" ?'animateR5':undefined}   >
      <div className='pImgs5' ></div>
          
          </div>
        
          </Col>
  </Row>) */
  :(undefined)
}


   
<Row style={{marginTop:"40px",marginBottom:"20px",position:"relative"}}>
    <Col className="d-flex justify-content-center" >
{fifthProjects.map(({},i)=> <img className='studyImg' src={id===i+1+""? studybtn :studygbtn} onClick={handleClick}  id={i+1+""} alt=""></img>)}
     {/* <img className='studyImg' src={id==="1"? studybtn :studygbtn} onClick={handleClick}  id="1" alt=""></img>
    <img className='studyImg' src={id==="2"? studybtn :studygbtn}  onClick={handleClick} id="2" alt=""></img>
    <img className='studyImg' src={id==="3"? studybtn :studygbtn}  onClick={handleClick} id="3" alt=""></img>
    <img className='studyImg' src={id==="4"? studybtn :studygbtn}  onClick={handleClick} id="4" alt=""></img>
    <img className='studyImg' src={id==="5"? studybtn :studygbtn}  onClick={handleClick} id="5" alt=""></img> */}
    {/* <img className='studyImg' src={id==="6"? studybtn :studygbtn}  onClick={handleClick} id="6" alt=""></img> */}
          <Link className='projectLink' to="/projects" target='_top'>See all projects</Link>
         
    </Col>
    
</Row>

    </StyledCase>
  )
}

export default CaseStudies
