import { Route } from "react-router-dom";



import Contact from "./Contact";
import ContactContainer from "./ContactContainer";

export default (
  <>
    <Route
      path="/contact"
      exact
      element={
        // <RequireAuth>
          <Contact/>
        // </RequireAuth>
      }
    >
      <Route
        index
        element={
          // <RequireAuth>
            <ContactContainer />
          // </RequireAuth>
        }
      />
    </Route>
  </>
);
