import React from 'react'
import { Col, Row } from 'react-bootstrap'
import styled from "styled-components";

import './maintitle2.scss';
const StyledTitle= styled.div`


`;
function SectionTitleContent({fTitle,sTitle,subtitle,details}) {
  return (
    <StyledTitle>

    <Row className='RowTitle2' >
    <Col>
    <div className='beforeTitle2'  style={{margin:"0 30%"}} >
    <p className='mainTitle2'>

    {fTitle} 
    <span style={{color:"rgba(127, 245, 147, 1)"}}> {sTitle} </span> </p>
    <p className='title2'>
    {subtitle}
    </p></div>
    <p className='details2' >
    {details}
    </p>
  
    
    </Col>
  </Row>
  </StyledTitle>
  )
}

export default SectionTitleContent
