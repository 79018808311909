import {
  createContext,
  useState,
  useContext,
  useEffect,
  useCallback,
  cloneElement,
} from "react";
import { useLocation, Navigate, Link, useNavigate } from "react-router-dom";
import { useRequest } from "./request";

const AuthContext = createContext(null);

export function useAuth() {
  return useContext(AuthContext);
}

export default function AuthProvider({ children }) {
  let [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));
  const req = useRequest();

  useEffect(() => {
    if (user) return;
    const localUser = localStorage.getItem("user");
    console.log("localUser",localUser)
    if (localUser) setUser(JSON.parse(localStorage.getItem("user")));
  }, [user]);

  function changeCurrentUser(user) {
    console.log(user,"user...1")
    user.user.permissions = user.user.roles?.reduce(
      (a, b) => [...a, ...Object.keys(b.permissions)],
      []
    );

    delete user.user.roles;
    window.localStorage.setItem("user", JSON.stringify(user));
    setUser(user);
    console.log(user,"user...1")
  }

  let signin = useCallback(
    (data) => {
      return new Promise(async (resolve, reject) => {
        console.log("................");
        try { console.log("................",data);
          const res = await req(`auth/sign-in`, data);
          console.log("................",res);
          window.localStorage.setItem("user",
            JSON.stringify({user:data.email, accessToken: res.accessToken,refreshToken:res.refreshToken})
          );
        
          setUser({user:data.email, accessToken:res.accessToken,refreshToken:res.refreshToken,config: res.config });
         
         // changeCurrentUser({user:data.email, accessToken:res.accessToken,refreshToken:res.refreshToken});
          resolve(res);
        } catch (error) {
          reject(error);
          console.log("error",error)
        }
      });
    },
    [req]
  );

  let getIdentity = useCallback(() => {
    console.log("data")
    return new Promise(async (resolve, reject) => {
      try {
        const { identity_address } = user.user.manufacturer;
        const { uri, name } = await req(
          `identities/${identity_address}/`,
          null,
          {},
          true
        );
    
        const ipfsBaseUrl = user.user.configs.ipfs_base_url;
        const res = await fetch(`${ipfsBaseUrl}/${uri}/`, {
          method: "GET",
          redirect: "follow",
          // headers: {
          //   Authorization: `jwt ${window.localStorage.getItem("token")}`,
          // },
        });

        let profile;

        // if(res.redirected) {
        profile = await fetch(res.url);

        resolve({
          name,
          ...(await profile.json()),
        });
        // }
      } catch (error) {
        reject(error);
      }
    });
  }, [req, user]);

  const setUserIdentity = useCallback((publicKey) => {
    // setUser((user) => {
    //   const newUser = user;
    //   newUser.manufacturer.identity_address = publicKey;
    //   return newUser;
    // });
    const newUser = JSON.parse(localStorage.getItem("user"));
    newUser.user.manufacturer.identity_address = publicKey;
    setUser(newUser);
    window.localStorage.setItem("user", JSON.stringify(newUser));
  }, []);

  let editAccount = useCallback(
    (data) => {
      return new Promise(async (resolve, reject) => {
        try {
          const resData = await req(
            `rest-auth/user/`,
            data,
            { method: "PATCH" },
            true
          );
          changeCurrentUser(resData);
          resolve(resData);
        } catch (error) {
          reject(error);
          console.log(error);
        }
      });
    },
    [req]
  );
  const[email,setEmail]=useState("")
  let createAccount = useCallback(
    (data) => {
      return new Promise(async (resolve, reject) => {
        try {
          const resData = await req(
            `auth/sign-up`,
            data,
            { method: "POST" },
            false
          );
         // changeCurrentUser(resData);
        // setaccAccess(resData.accessToken)
          resolve(resData);
          console.log(data.email)
          setEmail(data.email);
          email=data.email;
        } catch (error) {
          reject(error);
          console.log(error);
        }
      });
    },
    [req]
  );

  let changePass = useCallback(
    (data) => {
      return new Promise(async (resolve, reject) => {
        try {
          const resData = await req(
            `rest-auth/password/change/`,
            data,
            { method: "POST" },
            true
          );
          resolve(resData);
        } catch (error) {
          reject(error);
        }
      });
    },
    [req]
  );
const[passStatus,setPassSatus]=useState("")
  let forgotPass = useCallback(
    (data) => {
      //data is email
      return new Promise(async (resolve, reject) => {
        try {
          const resData = await req(
            `auth/forgot-password`,
            data,
            { method: "POST" },
            false
          );
       
          resolve(resData);
          setPassSatus(resData.status)
          passStatus=resData.status
          console.log("in forgotpass form",resData.status)
          console.log("in forgotpass form passSattus",passStatus)
        } catch (error) {
          reject(error);
        }
      });
    },
    [req]
  );

  let setPass = useCallback(
    (data) => {
      return new Promise(async (resolve, reject) => {
        try {
          const res = await req(
            `rest-auth/password/reset/confirm/`,
            data,
            { method: "POST" },
            false
          );
          resolve(res);
        } catch (error) {
          reject(error);
        }
      });
    },
    [req]
  );
  const[resendRes,setResendRes]=useState({})
  let updatedValue = {};
  let resendVerification = useCallback(
    (data) => {
      return new Promise(async (resolve, reject) => {
        try {
          const res = await req(
            `auth/email/resend-verification'`,
            data,
            { method: "POST" },
           true
          );
          resolve(res);
          console.log("res resend :",res)
          updatedValue = {"status":res.status,"message":res.message};
          setResendRes(resendRes => ({
            ...resendRes,
            ...updatedValue
          }));
        } catch (error) {
          reject(error);
        }
      });
    },
    [req]
  );
  const saveTokens = useCallback(
    (newTokens) => {
      const userTokens = { ...user, tokens: {...user.refreshToken, ...newTokens} };
      window.localStorage.setItem("user", JSON.stringify(userTokens));
      setUser(userTokens);
    },
    [user]
  );

  let signout = useCallback((redirect = true) => {
    return new Promise((resolve) => {
      window.localStorage.removeItem("user");
      setUser(null);
      // if (redirect) window.location.href = "/";
      resolve();
    });
  }, []);

  let value = {
    user,
    signin,
    changePass,
    forgotPass,
    signout,
    editAccount,
    setPass,
    getIdentity,
    setUserIdentity,
    saveTokens,passStatus,createAccount,resendVerification,resendRes,email
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

// export function RequireAuth({ children }) {
//   const location = useLocation();
//   let { user } = useAuth();

//   if (!user) return <Navigate to="/login" state={{ from: location }} replace />;

//   if (
//     user.user.manufacturer?.identity_address === "" &&
//     location.pathname !== "/wallet/create" &&
//     location.pathname !== "/wallet/unlock"
//   )
//     return <Navigate to="/wallet/create" replace />;

//   return <Navigate to="/no-access" replace />;
// }
export function RequireAuth({ children }) {
  const location = useLocation();
  let { user } = useAuth();

  if (!user?.accessToken)
  
    return <Navigate to="/login" state={{ from: location }} replace />;
    //return <Navigate to="/" replace />;
  return children;
}
