import React from 'react'
import { Col, Row } from 'react-bootstrap'
import styled from "styled-components";

import './maintitle.scss';
const StyledTitle= styled.div`


`;
function MainTitle({fTitle,sTitle,subtitle,details}) {
  return (
    <StyledTitle>

    <Row className='RowTitle' >
    <Col >
    <div className='beforeTitle1'>
    <p className='mainTitle1'>

    {fTitle} 
    <span style={{color:"rgba(127, 245, 147, 1)"}}> {sTitle} </span> </p>
    <span className='title1'>
    {subtitle}
    </span>
    <p className='details1' >
    {details}
    </p></div>
  
    
    </Col>
  </Row>
  </StyledTitle>
  )
}

export default MainTitle
