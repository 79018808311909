import React from "react";
import { Col, Row } from "react-bootstrap";
import styled from "styled-components";
import bgc from "../assets/hero/bgc2.svg";
import "./navContent.scss"
const StyledTitle = styled.div`
  text-align: center;
  background-image: url(${bgc});
  background-size: cover;
  margin-top: 10px;
  padding: 40px;


`;
function NavContent({ fTitle, sTitle, subtitle, details }) {
  return (
    <StyledTitle>
      <Row >
        <Col style={{justifyContent:"center"}}>
        <div className='beforeTitle'>
          <p className="mainTitle">
            {fTitle}
            <span style={{ color: "rgba(127, 245, 147, 1)" }}>
              {sTitle}
            </span>
          </p>
          <p className="title">{subtitle}</p>

          </div>
         
          <p className="details">{details}</p>
        </Col>
      </Row>
    </StyledTitle>
  );
}

export default NavContent;
