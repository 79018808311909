import React, { useEffect, useRef } from "react";
import { HeroContainer } from "./styles/style";
// import Vector from "../../../assets/hero/hero.svg";
//import herotext from "../../../assets/hero/hnndeshero.svg";
// import herotext from "../../../assets/Hnndes1.svg"
import scroll from "../../../assets/hero/scroll.svg";
import Video from "./company.mp4";
import "./styles/hero.scss";
import Nav from "./Nav";
// import Carousel from "../../../ui/carousel/Carousel";
import RankSlider from "./RankSlider";
// import NavContent from "../../NavContent";
import posterURL from "../../../assets/BasicBackground.svg"
var num = 0;

function countUp() {
  if (document.getElementById("t2")) {
    document.getElementById("t2").style.opacity = num;
    document.getElementById("t1").style.opacity = num;
    document.getElementById("t3").style.opacity = num;
    document.getElementById("t4").style.opacity = num;
    document.getElementById("t5").style.opacity = num;
    document.getElementById("t6").style.opacity = num;
    document.getElementById("t7").style.opacity = num;
    document.getElementById("t8").style.opacity = num;

    if (num > 0.95) {
      document.getElementById("t1").style.opacity = 0;
      document.getElementById("cc1").style.opacity = 0;

      document.getElementById("t2").style.opacity = 0;
      document.getElementById("cc2").style.opacity = 0;

      document.getElementById("t3").style.opacity = 0;
      document.getElementById("cc3").style.opacity = 0;

      document.getElementById("t4").style.opacity = 0;
      document.getElementById("cc4").style.opacity = 0;

      document.getElementById("t5").style.opacity = 0;
      document.getElementById("cc5").style.opacity = 0;
      document.getElementById("t6").style.opacity = 0;
      document.getElementById("cc6").style.opacity = 0;

      document.getElementById("t7").style.opacity = 0;
      document.getElementById("cc7").style.opacity = 0;

      document.getElementById("t8").style.opacity = 0;
      document.getElementById("cc8").style.opacity = 0;
    }
    num += 0.06;
  }
}

setInterval(function () {
  if (num < 0.99) {
    countUp();
  }
}, 230);

function Hero() {
  let windowWidth = 1200;
  var mq991 = window.matchMedia("(max-width: 991px)");
  var mq464 = window.matchMedia("(max-width: 474px)");
  if (mq991.matches) windowWidth = 991;
  if (mq464.matches) windowWidth = 474;
  else windowWidth = 1200;
  const vidRef = useRef();

  useEffect(() => {
//     var video = document.getElementById('my-video');
// video.src={Video}
   vidRef.current.load();
  }, []);

  useEffect(() => {
    function handleResize() {
      mq991 = window.matchMedia("(max-width: 991px)");
      mq464 = window.matchMedia("(max-width: 474px)");
      console.log(windowWidth, mq991, mq464);
      if (mq991.matches) windowWidth = 991;
      if (mq464.matches) windowWidth = 474;
      else windowWidth = 1200;
    }
    window.addEventListener("resize", handleResize);
  });
  return (

    <HeroContainer>
      <Nav />

      {/*  */}
      {/* <div className="playerContainer">
 <video ref={ vidRef }
  muted
  autoPlay
  loop   className="videoPlayer" src={Video}></video></div> */}
      <div className="herotext">
        <div className="beforeSlagon">
          <p className="slagon">We build software you trust</p>
        </div>
        {/* <img src={herotext} alt="" width="100%" height="100%" /> */}
        <p className="herotext-p">
          We are a digital agency that builds world class products and helps
          businesses & brands around the world succeed in the digital age.We
          focus on the latest technology & help companies reach their goals with
          impactful & trust-worthy expertise.
        </p>
      </div>

      <div className="heroscroll">
        <img src={scroll} alt="" width="100%" height="100%" />
      </div>

      <RankSlider />
      {/* <div class="video-container"> */}
   
        <video
          ref={vidRef}
          muted
          autoPlay
          loop
          className="video-bg"
          src={Video}
           poster={posterURL}
       
        >
          <source id="my-video" src={Video} type="video/mp4"  style={{opacity:"0"}} />
       
        </video>
    
        
       <div className="bgcHero" ></div>  
      {/* </div> */}
      {/* vectors */}
      {/* <img src={Vector} width="100%" height="100%" alt="v" />
      <div className="circle">
        <svg viewBox="0 0 100 100" height="100px">
          <path
            id="t1"
            fill="none"
            stroke="#53DFC7"
            strokeWidth="1%"
            d="M 488 73 L 288 73 T 217 73 V 67 L 33 67 v -1 -20"
          />

          <circle id="cc1" r="4" fill="#53DFC7">
            <animateMotion
              id="c1"
              dur="2.3s"
              repeatCount="0"
              fill="freeze"
              path="M 488 73 L 288 73 T 217 73 V 67 L 33 67 v -1 -20"
            >
              <mpath xlinkHref="#t1" />
            </animateMotion>
          </circle>
        </svg>
      </div>
      <div className="circle2">
        <svg
          viewBox={
            windowWidth > 991
              ? "-100 0 300 170"
              : windowWidth > 474
              ? "0 0 220 160"
              : "0 0 10 200"
          }
          height="180px"
          width="240px"
        >
          <path
            id="t2"
            fill="none"
            stroke="#53DFC7"
            strokeWidth="1%"
            d="M 436 83 T 170 82 V 67 L 102 67 L 80 80 L -46 80"
          />

          <circle id="cc2" r="4" fill="#53DFC7">
            <animateMotion
              dur="3s"
              repeatCount="0"
              fill="freeze"
              path="M 436 83 T 170 82 V 67 L 102 67 L 80 80 L -46 80"
            >
              <mpath xlinkHref="#t2" />
            </animateMotion>
          </circle>
        </svg>
      </div>

      <div className="circle3">
        <svg
          viewBox={
            windowWidth > 991
              ? "0 0 300 170"
              : windowWidth > 474
              ? "0 0 220 160"
              : "100 0 10 200"
          }
          height="180px"
          width="240px"
        >
          <path
            id="t3"
            fill="none"
            stroke="#53DFC7"
            strokeWidth="1%"
            d="M 436 83 T 209 82 V 53 L 156 53 L 157 74 L 199 75 L 198 63 L 178 63"
          />

          <circle id="cc3" r="4" fill="#53DFC7">
            <animateMotion
              dur="3s"
              repeatCount="0"
              fill="freeze"
              path="M 436 83 T 209 82 V 53 L 156 53 L 157 74 L 199 75 L 198 63 L 178 63"
            >
              <mpath xlinkHref="#t3 " />
            </animateMotion>
          </circle>
        </svg>
      </div>

      <div className="circle4">
        <svg
          viewBox={
            windowWidth > 991
              ? "0 0 300 170"
              : windowWidth > 474
              ? "0 0 220 160"
              : "0 0 10 200"
          }
          height="180px"
          width="240px"
        >
          <path
            id="t4"
            fill="none"
            stroke="#53DFC7"
            strokeWidth="1%"
            d="M 436 83 T 327 82 V 46 L 303 42 L 280 78 L 113 78"
          />

          <circle id="cc4" r="4" fill="#53DFC7">
            <animateMotion
              dur="3s"
              repeatCount="0"
              fill="freeze"
              path="M 436 83 T 327 82 V 46 L 303 42 L 280 78 L 113 78"
            >
              <mpath xlinkHref="#t4" />
            </animateMotion>
          </circle>
        </svg>
      </div>

      <div className="circle5">
        <svg
          viewBox={
            windowWidth > 991
              ? "0 0 300 170"
              : windowWidth > 474
              ? "0 0 220 160"
              : "0 0 10 200"
          }
          height="180px"
          width="240px"
        >
          <path
            id="t5"
            fill="none"
            stroke="#53DFC7"
            strokeWidth="1%"
            d="M 436 83 T 209 82 V 53 L 156 53 L 157 74 L 124 75 L 86 56"
          />

          <circle id="cc5" r="4" fill="#53DFC7">
            <animateMotion
              dur="3s"
              repeatCount="0"
              fill="freeze"
              path="M 436 83 T 209 82 V 53 L 156 53 L 157 74 L 124 75 L 86 56"
            >
              <mpath xlinkHref="#t5" />
            </animateMotion>
          </circle>
        </svg>
      </div>

      <div className="circle6">
        <svg
          viewBox={
            windowWidth > 991
              ? "0 0 300 170"
              : windowWidth > 474
              ? "0 0 220 160"
              : "0 0 10 200"
          }
          height="180px"
          width="240px"
        >
          <path
            id="t6"
            fill="none"
            stroke="#53DFC7"
            strokeWidth="1%"
            d="M 436 83 T 204 83 V 54 L 162 54 L 162 79 L 123 79 L 87 59"
          />

          <circle id="cc6" r="4" fill="#53DFC7">
            <animateMotion
              dur="3.2s"
              repeatCount="0"
              fill="freeze"
              path="M 436 83 T 204 83 V 54 L 162 54 L 162 79 L 123 79 L 87 59"
            >
              <mpath xlinkHref="#t6" />
            </animateMotion>
          </circle>
        </svg>
      </div>

      <div className="circle7">
        <svg
          viewBox={
            windowWidth > 991
              ? "0 0 300 170"
              : windowWidth > 474
              ? "0 0 220 160"
              : "0 0 10 200"
          }
          height="180px"
          width="240px"
        >
          <path
            id="t7"
            fill="none"
            stroke="#53DFC7"
            strokeWidth="1%"
            d="M 436 83 T 206 83 V 124 L 164 124 L 165 148 L 122 149"
          />

          <circle id="cc7" r="4" fill="#53DFC7">
            <animateMotion
              dur="3.2s"
              repeatCount="0"
              fill="freeze"
              path="M 436 83 T 206 83 V 124 L 164 124 L 165 148 L 122 149"
            >
              <mpath xlinkHref="#t7" />
            </animateMotion>
          </circle>
        </svg>
      </div>

      <div className="circle8">
        <svg
          viewBox={
            windowWidth > 991
              ? "0 0 300 170"
              : windowWidth > 474
              ? "0 0 220 160"
              : "0 0 10 200"
          }
          height="180px"
          width="240px"
        >
          <path
            id="t8"
            fill="none"
            stroke="#53DFC7"
            strokeWidth="1%"
            d="M 481 84 T 220 83 V 42 L 384 43 L 401 61"
          />

          <circle id="cc8" r="4" fill="#53DFC7">
            <animateMotion
              dur="3.2s"
              repeatCount="0"
              fill="freeze"
              path="M 436 84 T 220 83 V 42 L 384 43 L 401 61"
            >
              <mpath xlinkHref="#t8" />
            </animateMotion>
          </circle>
        </svg>
      </div> */}
    </HeroContainer>

  );
}

export default Hero;
