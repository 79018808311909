import styled from "styled-components";

export const HeroContainer = styled.div`
/* 
  width: 100%;
  height: 100vh; */
  /* background-image:url("../../../../assets/BasicBackground.svg"); */

  /* background-size:cover; */
  position: relative;
  padding-bottom: 56.25%;
    /* padding-top: 25px; */
    height: 0;
    
    @media screen and (max-width: 474px) {
      padding-bottom: 56.25%;
      //padding-top: 25px; 
    }
   
  img {
    object-fit: cover;
  }
  .circle {
    position: absolute;
    right: 0;
    top: 68px;
  }
  .circle2 {
    position: absolute;
    right: 0;
    top: 140px;
  }

  .circle3 {
    position: absolute;
    right: 0;
    top: 215px;
  }
  .circle4 {
    position: absolute;
    right: 0;
    top: 280px;
  }

  .circle5 {
    position: absolute;
    right: 0;
    top: 360px;
  }
  .circle6 {
    position: absolute;
    right: 0;
    top: 370px;
  }
  .circle7 {
    position: absolute;
    right: 0;
    top: 430px;
  }

  .circle8 {
    position: absolute;
    right: 0;
    top: 480px;
  }
  .testfont {
    font-size: calc(15px + 0.390625vw);
  }
  .logo {
    position: absolute;
    top: 20px;
    left: 80px;
    width: 59px;
    height: 80px;
    cursor: pointer;
    @media screen and (max-width: 1200px) {
      left: 40px;
    }
    @media screen and (max-width: 991px) {
      left: 20px;
    }
    @media screen and (max-width: 767px) {
     // width: 60px;
     // height: 60px;
    display: none;
    }
    @media screen and (max-width: 474px) {
      left: 20px;
      top: 20px;
      width: 59px;
      height: 80px;
      display:none;
    }
  }
  .logoMob{
    position: absolute;
    top: 20px;
    left: 80px;
  
    display: none;
    @media screen and (max-width:767px) {
      /* left: 20px;
      top: 20px; */
     // width: 50px;
    //  height: 50px;
    width: 32px;
height: 42.24px;
top: 12px;
left: 12px;




      display:block;
    }
  }
  .menu {
    position: absolute;
    top: 22px;
    right: 40px;
    height: 70px;
    cursor: pointer;
    display:none;
    @media screen and (max-width: 991px) {
      height: 50px;
      display:block;
    }
    @media screen and (max-width: 767px) {
      height: 40px;
      display:block;
      top: 12px;
right: 12px;
    }
    @media screen and (max-width: 474px) {
      top: 12px;
      right: 12px;
      display:block;
      //right: 20px;
    }
  }
`;
