import React from "react";
import Header from "./Header/Header";
import FooterLayout from "./Footer/FooterLayout";
const Layout = ({ children }) => {
  return (
    <>
      {/* <Header /> */}
      {children}
      <FooterLayout />
    </>
  );
};

export default Layout;
