import React from "react";
import "./styles/rankslider.scss";
import Slider from "react-slick";
import ola from "../../../assets/olaRank.svg"
import esraa from "../../../assets/esraRank.svg"
import bayan from "../../../assets/bayanR.svg"
import basem from "../../../assets/basemR.svg"
import alaa from "../../../assets/alaaR.svg"
import mohamad from "../../../assets/mouhamadR.svg"
import batool from "../../../assets/batoulR.svg"
import alice  from "../../../assets/aliceR.svg"
import nour from "../../../assets/nour.svg"
import martin from "../../../assets/martin.svg"
import carol from "../../../assets/carol.svg"
import rama from "../../../assets/rama.svg"
import elie from "../../../assets/elie.svg"
import esraa1 from "../../../assets/esraa1.svg"
import thamen from "../../../assets/thamen.svg"
import anas from "../../../assets/anas.svg"
import star from "../../../assets/hero/star.svg";
function RankSlider() {
  const settings = {
    autoplay: true,
    dots: false,
    arrows: false,
    fade: true,
    infinite: true,
    speed: 9000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  //no api yet available
  const fakedata = [
    {
      id: 1,
      imgsrc:
        ola,
      name: "Ola",
      
      title: "UIUX Designer",
      company: "You can't use up creativity. The more you use, the more you have. ",
    },

    {
      id: 2,
      imgsrc:esraa,
      name: "Esraa",
    
      title: "UIUX Designer",
      company: "Simplicity is key: makes it easy for users to interact with the product efficiently.",
    },
    {
      id: 3,
      imgsrc:bayan,
      name: "Bayan",
    
      title: "Flutter Developer",
      company: "Take the risks, or keep dreaming",
    },
    {
      id: 4,
      imgsrc:basem,
      name: "Basem",
    
      title: "Software Engineer",
      company: "Life is really simple, but we insist on making it complicated.",
    },
    {
      id: 5,
      imgsrc:alaa,
      name: "Alaa",
    
      title: "ReactJS Developer",
      company: "The only way to do great work is to love what you do.",
    },
    {
      id: 6,
      imgsrc:mohamad,
      name: "Mouhamad",
    
      title: "System analyst",
      company: "Iteration is the key: learn, Build, test, repeat. works for software and personal growth.",
    },
    {
      id: 7,
      imgsrc:batool,
      name: "Batool",
    
      title: "ReactJS Developer",
      company: "There is no path to happiness; Happiness is the way",
    },
    {
      id: 8,
      imgsrc:alice,
      name: "Alaa",
    
      title: "UIUX Designer",
      company:"Life is full of opportunity. All you have to do is open your eyes",
    },
    {
      id: 9,
      imgsrc:nour,
      name: "Nour",
    
      title: "UIUX Designer",
      company:"To create a memorable design, start with a thought that’s worth remembering.",
    },
    {
      id: 10,
      imgsrc:martin,
      name: "Martin",
    
      title: "Django Developer",
      company:"Light or Fire, believe me, it shines.",
    },
    {
      id: 11,
      imgsrc:carol,
      name: "Carol",
    
      title: "Web flow Developer",
      company:"You only live once, but if you do it right, once is enough.",
    },
    {
      id: 12,
      imgsrc:rama,
      name: "Rama",
    
      title: "Django Developer",
      company:"There is no missed opportunity, everything that you missed is not for you.",
    },
    {
      id: 13,
      imgsrc:elie,
      name: "Elie",
    
      title: "NodeJS Developer",
      company:"Innovation and quality, our code shapes the future.",
    },
    {
      id: 14,
      imgsrc:esraa1,
      name: "Esraa",
    
      title: "QA Specialist",
      company:"With confidence, you have won before you have started.",
    },
    {
      id: 15,
      imgsrc:thamen,
      name: "Thameen",
    
      title: "Web flow Developer",
      company:"The best way to predict your future is to create it.",
    },
    {
      id: 16,
      imgsrc:anas,
      name: "Anas",
    
      title: "NodeJS Developer",
      company:"It's easy to win forgiveness for being wrong; being right is what gets you into real trouble.",
    },
  ];
  return (
 
    <div className="rankslider">
      <Slider className="slider" {...settings}>
        {fakedata.map((slide) => {
          return (
            <>
           
              <div className="slide">
                {/* <div className="d-flex"> */}
                  <div className="imgrank">
                    <img
                      src={slide.imgsrc}
                      alt={slide.name}
                      width="100%"
                      height="100%"
                    />
                  </div>
                  <div className="text">
                    <p>
                      <span className="name">{slide.name}</span>
                   
                    </p>
                    <p className="position">
                      <span>{slide.title}</span>
                      <span className="sscompany">{slide.company}</span>
                    </p>
                  </div>
                {/* </div> */}
           
              </div>
            </>
          );
        })}
      </Slider>
    
    </div>
  );
}

export default RankSlider;
